import { App } from './app';
import { Section } from './section';
import { Template } from './template';

export class Page {

    public id: number;
    public customer_id: number;
    public title: string;
    public typ: string;
    public txt: string;
    public url: string;
    public pages: Page[];
    public status: number;

}

