import { App } from './../../../../../shared/models/app';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { DataService } from 'src/app/Services/data.service';

@Component({
  selector: 'app-intranet-news-stats',
  templateUrl: './intranet-news-stats.component.html',
  styleUrls: ['./intranet-news-stats.component.scss']
})
export class IntranetNewsStatsComponent implements OnInit {

  heading = 'Statistik';
  subheading = 'Nyheter';
  icon = 'pe-7s-users icon-gradient bg-arielle-smile';

  apps: App[] = [];

  constructor(public data: DataService, public api: ApiService, private router: Router) { }

  ngOnInit(): void {

    this.data.currentUser.apps.forEach((data: App) => {



    })

  }

}
