import { Component, OnInit } from '@angular/core';
import { AppUser } from '../../../../../shared/models/appuser';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { DataService } from 'src/app/Services/data.service';
import { Response } from '../../../../../shared/models/response';

@Component({
  selector: 'app-admin-app-user-edit',
  templateUrl: './admin-app-user-edit.component.html',
  styleUrls: ['./admin-app-user-edit.component.scss']
})
export class AdminAppUserEditComponent implements OnInit {

  heading = 'Skapa prenumerant';
  subheading = 'Här skapar du en ny prenumerant';
  icon = 'pe-7s-users icon-gradient bg-arielle-smile';

  public user: AppUser = new AppUser();

  constructor(private route: ActivatedRoute,
    private api: ApiService,
    private router: Router,
    private data: DataService) {

    let id = +this.route.snapshot.paramMap.get("id");

    if (id > 0)
    {
        this.api.getAppUser(id).subscribe((data:AppUser) => {
          this.user = data;

        })
    }


   }

  ngOnInit() {
  }

  save() {

    this.api.setAppUser(this.user).subscribe((data:Response) => {

      if (data.code == 200)
      {
        this.router.navigateByUrl('/admin/allappusers')
      }
    })

  }



  public delete()
  {
    this.api.delAppUser(this.user.id).subscribe((data:Response) => {

        this.router.navigateByUrl('/admin/allusers')
    })

  }

}
