import { App } from "./app";

export class ExternalAd {

    public id: number;
    public code: string;
    public title: string;
    public type: string;
    public format: string;
    public date_start: string;
    public date_end: string;

}

