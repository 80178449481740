import { Customer } from './customer';
import { App } from './app';
import { LiveEventEvent } from './liveevent_event';


export class LiveEvent {

    public id: number;
    public starttime: String;
    public sport: number;
    public place: String;
    public title: String;
    public teamOneName: String;
    public teamTwoName: String;
    public teamOneMedia: String;
    public teamTwoMedia: String;
    public duration: String;
    public status: number;
    public customer: Customer;
    public app: App;
    public txt: String;
    public events: LiveEventEvent[] = [];

}

