import { Component, OnInit } from '@angular/core';
import { Template } from '../../../../../shared/models/template';
import { DataService } from 'src/app/Services/data.service';
import { HttpClient } from '@angular/common/http';
import { Serie } from '../../../../../shared/models/serie';
import { DataTablesResponse } from '../../../../../shared/models/datatabelsResponse';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-series',
  templateUrl: './series.component.html',
  styleUrls: ['./series.component.scss']
})
export class SeriesComponent implements OnInit {
  baseUrl:string = environment.apiUrl;
  heading = 'Artikelserier';
  subheading = 'Här redigerar du artikelserierna';
  icon = 'pe-7s-network icon-gradient bg-malibu-beach';

  public series: Serie[] = [];

  dtOptions: DataTables.Settings = {};

  constructor(private data: DataService, private http: HttpClient) {
  }

  ngOnInit(): void {
    const that = this;

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      serverSide: true,
      processing: true,
      language: {
        "emptyTable":     "Ingen data är tillgänglig",
        "info":           "Visar _START_ till _END_ av _TOTAL_ rader",
        "infoEmpty":      "Visar 0 - 0 av 0 rader",
        "infoFiltered":   "(filtered from _MAX_ total entries)",
        "infoPostFix":    "",
        "thousands":      ",",
        "lengthMenu":     "Visa _MENU_ rader per sida",
        "loadingRecords": "Laddar...",
        "processing":     "Laddar...",
        "search":         "Sök:",
        "zeroRecords":    "Inget matchar sökningen",
        "paginate": {
            "first":      "Första",
            "last":       "Sista",
            "next":       "Nästa",
            "previous":   "Föregående"
        },
        "aria": {
            "sortAscending":  ": activate to sort column ascending",
            "sortDescending": ": activate to sort column descending"
        }
    },
      ajax: (dataTablesParameters: any, callback) => {
        that.http.post<DataTablesResponse>(
            this.baseUrl + '/api/series',
            dataTablesParameters, {}
        ).subscribe(resp => {
          that.series = resp.data;

          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
      },
      columns: [{ data: 'id' }, { data: 'name' }]
    };
  }

  ngOnDestroy(): void {
  }
}
