import { Customer } from './customer';
import { App } from './app';

export class EventUrl {

    public id: number;
    public name: String;
    public url: String;
    public status: number;
    public app: App;
}

