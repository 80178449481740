import { ProductCategory } from '../../../../shared/models/productCategory';
import { DefaultLunch } from '../../../../shared/models/defaultlunch';
import { App } from './../../../../shared/models//app';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { User } from '../../../../shared/models//user';
import { Response } from '../../../../shared/models//response';
import { Customer } from '../../../../shared/models//customer';
import { DataService } from './data.service';
import { Ad } from '../../../../shared/models//ad';
import { Template } from '../../../../shared/models//template';
import { Section } from '../../../../shared/models//section';
import { Media } from '../../../../shared/models//media';
import { Profile } from '../../../../shared/models//profile';
import { Serie } from '../../../../shared/models//serie';
import { ToastrService } from 'ngx-toastr';
import { Byline } from '../../../../shared/models//byline';
import { Place } from '../../../../shared/models//place';
import { Lunch } from '../../../../shared/models//lunch';
import { Event } from '../../../../shared/models//event';
import { Rss } from '../../../../shared/models//rss';
import { SportKey } from '../../../../shared/models//sportKey';
import { SportTeam } from '../../../../shared/models//sportTeam';
import { Contract } from '../../../../shared/models//contract';
import { Contact } from '../../../../shared/models//contact';
import { Page } from '../../../../shared/models//page';
import { SectionPage } from '../../../../shared/models//sectionPage';
import { Device } from '../../../../shared/models//device';
import { Emailtemplate } from '../../../../shared/models//emailtemplate';
import { Email } from '../../../../shared/models//email';
import { AppUser } from '../../../../shared/models//appuser';
import { EventCategory } from '../../../../shared/models//eventcategory';
import { Quiz } from '../../../../shared/models//quiz';
import { Recommend } from '../../../../shared/models//recommend';
import { RecommendCategory } from '../../../../shared/models//recommendCategory';
import { Product } from '../../../../shared/models//product';
import { GiftCard } from '../../../../shared/models//giftcard';
import { Todo } from '../../../../shared/models//todo';
import { AdTag } from '../../../../shared/models//adtag';
import { environment } from '../../environments/environment'
import { LiveEvent } from '../../../../shared/models//liveevent';
import { LiveEventEvent } from '../../../../shared/models//liveevent_event';
import { menuObject } from '../../../../shared/models/menuObject';
import { Feedback } from '../../../../shared/models//feedback';
import { CustomerLog } from '../../../../shared/models//customer_log';
import { AudioShow } from '../../../../shared/models//audioShow';
import { VideoShow } from '../../../../shared/models//videoShow';
import { Today } from '../../../../shared/models//today';
import { CityQuizQuestion } from '../../../../shared/models/CityQuizQuestion';
import { TravelStation } from '../../../../shared/models/travelStation';
import { EventUrl } from '../../../../shared/models/eventUrl';
import { PortalText } from '../../../../shared/models/portalText';
import { ExternalAd } from '../../../../shared/models/externalAd';
import { AdLog } from '../../../../shared/models/adLog';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private baseUrl:string = environment.apiUrl;

  constructor(private http: HttpClient, private data: DataService, private toastr: ToastrService) { }

  public login(email:string, password:string){

    let postObject = {
      email: email,
      password: password
    }

    return this.http.post(this.baseUrl + '/api/login', postObject).pipe(map((data:Response) => {
      return data;
    }));
  }

  public send_restore(email:string)
  {
    let postObject = {
      email: email
    }

    return this.http.post(this.baseUrl + '/api/restore', postObject).pipe(map((data:Response) => {
      return data;
    }));
  }

  public send_welcome(id: number)
  {
    let postObject = {
      id: id
    }

    return this.http.post(this.baseUrl + '/api/welcome', postObject).pipe(map((data:Response) => {
      return data;
    }));
  }

  public send_pren(id: number)
  {
    let postObject = {
      id: id
    }

    return this.http.post(this.baseUrl + '/api/prenmail', postObject).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getMenu(id: number)
  {
    return this.http.get(this.baseUrl + '/api/menu?app_id=' + id).pipe(map((data:menuObject[]) => {
      return data;
    })); 
  }

  public setMenu(menuObjects: menuObject[], app_id: number)
  {

    let postObject = {
      id: app_id,
      menus: menuObjects
    }

    return this.http.post(this.baseUrl + '/api/menu', postObject).pipe(map((data:Response) => {
      return data;
    })); 
  }

  public getUsers(){
    return this.http.get(this.baseUrl + '/api/users').pipe(map((data:User[]) => {
      return data;
    }));
  }

  public getEmployees(){
    return this.http.get(this.baseUrl + '/api/users?alt=employees').pipe(map((data:User[]) => {
      return data;
    }));
  }

  public getColumnists(appstr){

    return this.http.get(this.baseUrl + '/api/users?alt=columnists&appstr=' + appstr).pipe(map((data:User[]) => {
      return data;
    }));
  }



  public getUser(id: number){
    return this.http.get(this.baseUrl + '/api/user/' + id).pipe(map((data:User) => {
      return data;
    }));
  }

  public getUserByToken(token: string){
    return this.http.get(this.baseUrl + '/api/user/' + token).pipe(map((data:User) => {
      return data;
    }));
  }

  public setUser(user: User){
    return this.http.post(this.baseUrl + '/api/user', user).pipe(map((data:Response) => {
      return data;
    }));
  }

  public delUser(id: number)
  {
    return this.http.get(this.baseUrl + '/api/user/delete?id=' + id).pipe(map((data:any) => {
      return data;
    }));
  }

  public setUserAvatar(user_id: number, logo_str:string){

    let postObject = {
      id: user_id,
      avatar_str: logo_str
    }

    return this.http.post(this.baseUrl + '/api/user/avatar', postObject).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getAppUser(id: number){
    return this.http.get(this.baseUrl + '/api/appuser/' + id).pipe(map((data:AppUser) => {
      return data;
    }));
  }

  public setAppUser(user: AppUser){
    return this.http.post(this.baseUrl + '/api/postappuser', user).pipe(map((data:Response) => {
      return data;
    }));
  }

  public delAppUser(id: number)
  {
    return this.http.get(this.baseUrl + '/api/appuser/delete?id=' + id).pipe(map((data:any) => {
      return data;
    }));
  }

  public getApps(){
    return this.http.get(this.baseUrl + '/api/apps').pipe(map((data:App[]) => {
      return data;
    }));
  }

  public setApp(app: App){
    return this.http.post(this.baseUrl + '/api/app', app).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getApp(id: number){
    return this.http.get(this.baseUrl + '/api/app/' + id).pipe(map((data:App) => {
      return data;
    }));
  }

  public setPage(page: Page){
    return this.http.post(this.baseUrl + '/api/page', page).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getPage(id: number){
    return this.http.get(this.baseUrl + '/api/page/' + id).pipe(map((data:Page) => {
      return data;
    }));
  }

  public getPages(id: number){
    return this.http.get(this.baseUrl + '/api/pages?customer_id=' + id).pipe(map((data:Page[]) => {
      return data;
    }));
  }

  public delPage(id: number){
    return this.http.get(this.baseUrl + '/api/page/delete?id=' + id).pipe(map((data:Response) => {
      return data;
    }));
  }

  public setProduct(product: Product){
    return this.http.post(this.baseUrl + '/api/product', product).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getProduct(id: number){
    return this.http.get(this.baseUrl + '/api/product/' + id).pipe(map((data:Product) => {
      return data;
    }));
  }

  public getProducts(id: number){
    return this.http.get(this.baseUrl + '/api/products?customer_id=' + id).pipe(map((data:Product[]) => {
      return data;
    }));
  }

  public delProduct(id: Number){
    return this.http.get(this.baseUrl + '/api/product/delete?id=' + id).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getProductCategories() {
    return this.http.get(this.baseUrl + '/api/productcategories?show=all').pipe(map((data:ProductCategory[]) => {
      return data;
    }));
  }

  public setContractImg(contract_id: Number, img_str:string){

    let postObject = {
      id: contract_id,
      img_str: img_str
    }

    return this.http.post(this.baseUrl + '/api/contract/img', postObject).pipe(map((data:Response) => {
      return data;
    }));
  }

  public setCustomerLogo(customer_id: number, logo_str:string){

    let postObject = {
      id: customer_id,
      logo_str: logo_str
    }

    return this.http.post(this.baseUrl + '/api/customer/logo', postObject).pipe(map((data:Response) => {
      return data;
    }));
  }

  public setAppIcon(app_id: number, icon_str:string, type){

    let postObject = {
      id: app_id,
      icon_str: icon_str,
      type: type
    }

    return this.http.post(this.baseUrl + '/api/app/icon', postObject).pipe(map((data:Response) => {
      return data;
    }));
  }

  public setCustomer(customer: Customer){
    return this.http.post(this.baseUrl + '/api/customer', customer).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getCustomer(id: number){
    return this.http.get(this.baseUrl + '/api/customer/' + id).pipe(map((data:Customer) => {
      return data;
    }));
  }

  public getCustomers(){
    return this.http.get(this.baseUrl + '/api/customers?uid=' + this.data.currentUser.id).pipe(map((data:Customer[]) => {
      return data;
    }));
  }

  public getCustomersByContract(apps: App[]){

    let appstr = "";

    if (apps)
    {
      apps.forEach(x => {
        appstr += x.id + ","
      })

      appstr += "-1"
    }

    return this.http.get(this.baseUrl + '/api/customersbycontract?uid=' + this.data.currentUser.id + "&appstr=" + appstr).pipe(map((data:Customer[]) => {
      return data;
    }));
  }

  public getCustomersWithwarnings(apps: App[]){

    let appstr = "";

    if (apps)
    {
      apps.forEach(x => {
        appstr += x.id + ","
      })

      appstr += "-1"
    }

    return this.http.get(this.baseUrl + '/api/customersbywarnings?uid=' + this.data.currentUser.id + "&appstr=" + appstr).pipe(map((data:Customer[]) => {
      return data;
    }));
  }



  public getSalesStat(apps: App[], timestart:string, timestop:string)
  {
    let appstr = "";

    if (apps)
    {
      apps.forEach(x => {
        appstr += x.id + ","
      })

      appstr += "-1"
    }

    return this.http.get(this.baseUrl + '/api/salestimeline?uid=' + this.data.currentUser.id + "&appstr=" + appstr + "&timestart=" + timestart + "&timestop=" + timestop ).pipe(map((data:Customer[]) => {
      return data;
    }));

  }

  public getFollowers()
  {
    return this.http.get(this.baseUrl + '/api/followers?cid=' + this.data.currentUser.customer.id).pipe(map((data:Device[]) => {
      return data;
    }));
  }

  public getFollower(id: number)
  {
    return this.http.get(this.baseUrl + '/api/follower/' + id + '?cid=' + this.data.currentUser.customer.id).pipe(map((data:Device) => {
      return data;
    }));
  }

  public getSummary(app_id: number)
  {
    return this.http.get(this.baseUrl + '/api/summary?app_id=' + app_id).pipe(map((data:any) => {
      return data;
    }));
  }

  public getSummaryCount()
  {
    return this.http.get(this.baseUrl + '/api/summary_count').pipe(map((data:any) => {
      return data;
    }));
  }

  public clearSummary(app_id: number)
  {
    return this.http.put(this.baseUrl + '/api/summary?app_id=' + app_id, {}).pipe(map((data:any) => {
      return data;
    }));
  }  

  public deleteSummary(id: number)
  {
    return this.http.delete(this.baseUrl + '/api/summary/' + id).pipe(map((data:any) => {
      return data;
    }));
  }

  public getStat()
  {
    return this.http.get(this.baseUrl + '/api/stats?uid=' + this.data.currentUser.id + "&cid=" + this.data.currentUser.customer.id).pipe(map((data:any) => {
      return data;
    }));
  }

  public getNewsStat(week: Number)
  {
    return this.http.get(this.baseUrl + '/api/stats/news?week=' + week).pipe(map((data:any) => {
      return data;
    }));
  }

  public addFamilyPageUrl(url: string, app_id: number)
  {
    return this.http.post(this.baseUrl + '/api/familypageurl', {url: url, app_id: app_id}).pipe(map((data:any) => {
      return data;
    }));
  }

  public delCustomer(id: number)
  {
    return this.http.get(this.baseUrl + '/api/customer/delete?id=' + id).pipe(map((data:any) => {
      return data;
    }));
  }

  public setProfile(profile: Profile){
    return this.http.post(this.baseUrl + '/api/profile', profile).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getProfile(id: number){
    return this.http.get(this.baseUrl + '/api/profile/' + id).pipe(map((data:Profile) => {
      return data;
    }));
  }

  public setContact(contact: Contact){
    return this.http.post(this.baseUrl + '/api/contact?customer_id=' + this.data.currentUser.customer.id, contact).pipe(map((data:Response) => {
      return data;
    }));
  }

  public setCustomerLog(customerlog: CustomerLog){
    return this.http.post(this.baseUrl + '/api/customerlog', customerlog).pipe(map((data:CustomerLog) => {
      return data;
    }));
  }

  public getContact(id: number){
    return this.http.get(this.baseUrl + '/api/contact/' + id).pipe(map((data:Contact) => {
      return data;
    }));
  }

  public delContact(id: number){
    return this.http.get(this.baseUrl + '/api/contact/delete?id=' + id).pipe(map((data:Contact) => {
      return data;
    }));
  }



  public previewProfile(profile: Profile){
    return this.http.post(this.baseUrl + '/api/profile/preview', profile).pipe(map((data:any) => {
      return data;
    }));
  }

  public setAd(ad: Ad){
    return this.http.post(this.baseUrl + '/api/ad', ad).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getAd(id: number){
    return this.http.get(this.baseUrl + '/api/ad/' + id).pipe(map((data:Ad) => {
      return data;
    }));
  }

  public getAdTags(onlyHidden: boolean = false){
    return this.http.get(this.baseUrl + '/api/adtags?onlyhidden=' + onlyHidden).pipe(map((data:AdTag[]) => {
      return data;
    }));
  }

  public setAdLog(adLog: AdLog){
    return this.http.post(this.baseUrl + '/api/adlog', adLog).pipe(map((data:Response) => {
      return data;
    }));
  }

  public updateAdTag(tag: AdTag){
    return this.http.post(this.baseUrl + '/api/adtag', tag).pipe(map((data:AdTag[]) => {
      return data;
    }));
  }

public spellCheck(text: String){
  return this.http.post(this.baseUrl + '/api/spell_check', {text}).pipe(map((data:any) => {
    return data;
  }));
}


  public reImportAd(id: number){
    return this.http.get(this.baseUrl + '/api/ad/reimport/' + id).pipe(map((data:Response) => {
      return data;
    }));
  }

  public generatePDF(id: number){
    return this.http.get(this.baseUrl + '/api/ad/generatepdf/' + id).pipe(map((data:any) => {
      return data;
    }));
  }

  public delAd(id: number)
  {
    return this.http.get(this.baseUrl + '/api/ad/delete?id=' + id).pipe(map((data:any) => {
      return data;
    }));
  }

  public getMonthlyAds(c_id: number){
    return this.http.get(this.baseUrl + '/api/customer/monthstat?cid=' + c_id).pipe(map((data:any) => {
      return data.result;
    }));
  }

  public previewAd(ad: Ad){
    return this.http.post(this.baseUrl + '/api/ad/preview', ad).pipe(map((data:any) => {
      return data;
    }));
  }

  public setSerie(serie: Serie){
    return this.http.post(this.baseUrl + '/api/serie', serie).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getSerie(id: number){
    return this.http.get(this.baseUrl + '/api/serie/' + id).pipe(map((data:Serie) => {
      return data;
    }));
  }

  public getSeries(){
    return this.http.get(this.baseUrl + '/api/series').pipe(map((data:Serie[]) => {
      return data;
    }));
  }

  public previewSerie(serie: Serie){
    return this.http.post(this.baseUrl + '/api/serie/preview', serie).pipe(map((data:any) => {
      return data;
    }));
  }

  public setTemplate(template: Template){
    return this.http.post(this.baseUrl + '/api/template', template).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getTemplate(id: number){
    return this.http.get(this.baseUrl + '/api/template/' + id).pipe(map((data:Template) => {
      return data;
    }));
  }

  public getTemplates(){
    return this.http.get(this.baseUrl + '/api/templates').pipe(map((data:Template[]) => {
      return data;
    }));
  }

  public setEmailTemplate(template: Emailtemplate){
    return this.http.post(this.baseUrl + '/api/emailtemplate', template).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getEmailTemplate(id: number){
    return this.http.get(this.baseUrl + '/api/emailtemplate/' + id).pipe(map((data:Emailtemplate) => {
      return data;
    }));
  }

  public delEmailTemplate(id: number){
    return this.http.get(this.baseUrl + '/api/emailtemplate/delete?id=' + id).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getEmailTemplates(){
    return this.http.get(this.baseUrl + '/api/emailtemplates').pipe(map((data:Emailtemplate[]) => {
      return data;
    }));
  }

  public getEmails(userid: number){
    return this.http.get(this.baseUrl + '/api/emails?uid=' + userid).pipe(map((data:Email[]) => {
      return data;
    }));
  }

  public setContract(contract: Contract){
    return this.http.post(this.baseUrl + '/api/contract', contract).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getContract(id: number){
    return this.http.get(this.baseUrl + '/api/contract/' + id).pipe(map((data:Contract) => {
      return data;
    }));
  }

  public delRecommend(id: number)
  {
    return this.http.get(this.baseUrl + '/api/recommend/delete?id=' + id).pipe(map((data:any) => {
      return data;
    }));
  }

  public setRecommend(recommend: Recommend){
    return this.http.post(this.baseUrl + '/api/recommend?admin=1', recommend).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getRecommend(id: number){
    return this.http.get(this.baseUrl + '/api/recommend/' + id).pipe(map((data:Recommend) => {
      return data;
    }));
  }

  public getAllRecommends(){
    return this.http.get(this.baseUrl + '/api/recommends/all').pipe(map((data:Recommend[]) => {
      return data;
    }));
  }

  public getRecommendCats(){
    return this.http.get(this.baseUrl + '/api/recommend/categories?all=1').pipe(map((data:RecommendCategory[]) => {
      return data;
    }));
  }

  public setRecommendNonDuplicates(id, nid){
    return this.http.get(this.baseUrl + '/api/recommend-duplication?id=' + id + "&nid=" + nid).pipe(map((data:any) => {
      return data;
    }));
  }

  public clearRecommendAdd(id){
    return this.http.get(this.baseUrl + '/api/recommend-clearadd?id=' + id ).pipe(map((data:any) => {
      return data;
    }));
  }

  getPortalText(id){
    return this.http.get(this.baseUrl + '/api/portal/text/' + id).pipe(map((data:PortalText) => {
      return data;
    }));
  }

  setPortalText(text: PortalText){
    return this.http.post(this.baseUrl + '/api/portal/text', text).pipe(map((data:Response) => {
      return data;
    }));
  }

  delPortalText(id){
    return this.http.delete(this.baseUrl + '/api/portal/text/' + id).pipe(map((data:any) => {
      return data;
    }));
  }

  getExternalAd(id){
    return this.http.get(this.baseUrl + '/api/externalad/' + id).pipe(map((data:ExternalAd) => {
      return data;
    }));
  }

  setExternalAd(ad: ExternalAd){
    return this.http.post(this.baseUrl + '/api/externalad', ad).pipe(map((data:Response) => {
      return data;
    }));
  }

  delExternalAd(id){
    return this.http.delete(this.baseUrl + '/api/externalad' + id).pipe(map((data:any) => {
      return data;
    }));
  }

  public delAudioShow(id: number)
  {
    return this.http.get(this.baseUrl + '/api/audio-show/delete?id=' + id).pipe(map((data:any) => {
      return data;
    }));
  }

  public setAudioShow(audioShow: AudioShow){
    return this.http.post(this.baseUrl + '/api/audio-show', audioShow).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getAudioShow(id: number){
    return this.http.get(this.baseUrl + '/api/audio-show/' + id).pipe(map((data:AudioShow) => {
      return data;
    }));
  }

  public getAudioShows(app_id: number){
    return this.http.get(this.baseUrl + '/api/audio-show?app_id=' + app_id).pipe(map((data:AudioShow[]) => {
      return data;
    }));
  }

  public getAudioShowsforApp(id: number){
    return this.http.get(this.baseUrl + '/api/audio-shows-for-admin?app_id=' + id).pipe(map((data:AudioShow[]) => {
      return data;
    }));
  }

  public syncAudioShow(episode_id: number, show_id: number){
    return this.http.get(this.baseUrl + '/api/audio-show/sync?episode_id=' + episode_id + "&show_id=" + show_id).pipe(map((data:any) => {
      return data;
    }));
  }

  public delVideoShow(id: number)
  {
    return this.http.get(this.baseUrl + '/api/video-show/delete?id=' + id).pipe(map((data:any) => {
      return data;
    }));
  }

  public setVideoShow(videoShow: VideoShow){
    return this.http.post(this.baseUrl + '/api/video-show', videoShow).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getVideoShow(id: number){
    return this.http.get(this.baseUrl + '/api/video-show/' + id).pipe(map((data:VideoShow) => {
      return data;
    }));
  }



  public setPlace(place: Place){
    return this.http.post(this.baseUrl + '/api/place', place).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getPlace(id: number){
    return this.http.get(this.baseUrl + '/api/place/' + id).pipe(map((data:Place) => {
      return data;
    }));
  }

  public getPlaces(){
    return this.http.get(this.baseUrl + '/api/places').pipe(map((data:Place[]) => {
      return data;
    }));
  }

  public setSection(section: Section){
    return this.http.post(this.baseUrl + '/api/section', section).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getSection(id: number){
    return this.http.get(this.baseUrl + '/api/section/' + id).pipe(map((data:Section) => {
      return data;
    }));
  }

  public getSections(customer_id: number = 0){
    return this.http.get(this.baseUrl + '/api/sections?cid=' + customer_id).pipe(map((data:Section[]) => {
      return data;
    }));
  }

  public setSectionPage(section: SectionPage){
    return this.http.post(this.baseUrl + '/api/sectionpage', section).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getSectionPage(id: number){
    return this.http.get(this.baseUrl + '/api/sectionpage/' + id).pipe(map((data:SectionPage) => {
      return data;
    }));
  }

  public getSectionPages(customer_id: number = 0){
    return this.http.get(this.baseUrl + '/api/sectionpages?cid=' + customer_id).pipe(map((data:Section[]) => {
      return data;
    }));
  }

  public setMedia(media: Media){
    return this.http.post(this.baseUrl + '/api/media', media).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getMedia(id: number){
    return this.http.get(this.baseUrl + '/api/media/' + id).pipe(map((data:Media) => {
      return data;
    }));
  }

  public delMedia(id: number)
  {
    return this.http.get(this.baseUrl + '/api/media/delete?id=' + id).pipe(map((data:any) => {
      return data;
    }));
  }

  public getMedias(customer_id: number = 0, get: string = ""){
    return this.http.get(this.baseUrl + '/api/medias?customer_id=' + customer_id + "&get=" + get).pipe(map((data:Media[]) => {
      return data;
    }));
  }

  public searchMedias(customer_id: number = 0, s:String, get:string = "", cat: number = 0){
    return this.http.get(this.baseUrl + '/api/medias?customer_id=' + customer_id + "&s=" + s + "&get=" + get + "&cat=" + cat).pipe(map((data:Media[]) => {
      return data;
    }));
  }

  public replaceMedia(media: Media, copy:Boolean){
    return this.http.post(this.baseUrl + '/api/media/replace?copy=' + copy, media).pipe(map((data:Response) => {
      return data;
    }));
  }

  public setByline(byline: Byline){
    return this.http.post(this.baseUrl + '/api/byline', byline).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getByline(id: number){
    return this.http.get(this.baseUrl + '/api/byline/' + id).pipe(map((data:Byline) => {
      return data;
    }));
  }

  public getBylines(){
    return this.http.get(this.baseUrl + '/api/bylines').pipe(map((data:Byline[]) => {
      return data;
    }));
  }

  public setLunch(lunch: Lunch){
    return this.http.post(this.baseUrl + '/api/lunch?customer_id=' + this.data.currentUser.customer.id, lunch).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getLunch(id: number){
    return this.http.get(this.baseUrl + '/api/lunch/' + id).pipe(map((data:Lunch) => {
      return data;
    }));
  }

  public setStandardLunch(lunch: Lunch){
    return this.http.post(this.baseUrl + '/api/standardlunch?customer_id=' + this.data.currentUser.customer.id, lunch).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getStandardLunch(){
    return this.http.get(this.baseUrl + '/api/standardlunch?customer_id=' + this.data.currentUser.customer.id).pipe(map((data:any[]) => {
      return data;
    }));
  }

  public setDefaultLunch(lunch: DefaultLunch){
    return this.http.post(this.baseUrl + '/api/defaultlunch?customer_id=' + this.data.currentUser.customer.id, lunch).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getDefaultLunch(id: number){
    return this.http.get(this.baseUrl + '/api/defaultlunch/' + id).pipe(map((data:DefaultLunch) => {
      return data;
    }));
  }

  public delDefaultLunch(id: number)
  {
    return this.http.get(this.baseUrl + '/api/defaultlunch/delete?id=' + id).pipe(map((data:any) => {
      return data;
    }));
  }

  public setQuiz(quiz: Quiz){
    return this.http.post(this.baseUrl + '/api/quiz', quiz).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getQuiz(id: number){
    return this.http.get(this.baseUrl + '/api/quiz/' + id).pipe(map((data:Quiz) => {
      return data;
    }));
  }

  public delQuiz(id: number)
  {
    return this.http.get(this.baseUrl + '/api/quiz/delete?id=' + id).pipe(map((data:any) => {
      return data;
    }));
  }

  public setRss(rss: Rss){
    return this.http.post(this.baseUrl + '/api/rss', rss).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getRss(id: number){
    return this.http.get(this.baseUrl + '/api/rss/' + id).pipe(map((data:Rss) => {
      return data;
    }));
  }

  public setSportKey(sportKey: SportKey){
    return this.http.post(this.baseUrl + '/api/sportkey', sportKey).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getSportKey(id: number){
    return this.http.get(this.baseUrl + '/api/sportkey/' + id).pipe(map((data:SportKey) => {
      return data;
    }));
  }

  public getSportKeys(){
    return this.http.get(this.baseUrl + '/api/sportkeys').pipe(map((data:SportKey[]) => {
      return data;
    }));
  }

  public setSportTeam(sportTeam: SportTeam){
    return this.http.post(this.baseUrl + '/api/sportteam', sportTeam).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getSportTeam(id: number){
    return this.http.get(this.baseUrl + '/api/sportteam/' + id).pipe(map((data:SportTeam) => {
      return data;
    }));
  }

  public setGiftcard(giftcard: GiftCard){
    return this.http.post(this.baseUrl + '/api/gift-voucher', giftcard).pipe(map((data:GiftCard) => {
      return data;
    }));
  }

  public getGiftcard(id: number){
    return this.http.get(this.baseUrl + '/api/gift-voucher/' + id).pipe(map((data:GiftCard) => {
      return data;
    }));
  }

  public setLiveEvent(event: LiveEvent){
    return this.http.post(this.baseUrl + '/api/livescoreevent', event).pipe(map((data:Response) => {
      return data;
    }));
  }

  public setLiveEventEvent(event: LiveEventEvent){
    return this.http.post(this.baseUrl + '/api/livescoreeventevent', event).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getLiveEvent(id: number){
    return this.http.get(this.baseUrl + '/api/livescoreevent/' + id).pipe(map((data:LiveEvent) => {
      return data;
    }));
  }

  public delLiveEvent(id: number)
  {
    return this.http.get(this.baseUrl + '/api/livescoreevent/delete?id=' + id).pipe(map((data:any) => {
      return data;
    }));
  }

  public setEvent(event: Event){
    return this.http.post(this.baseUrl + '/api/event', event).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getEvent(id: number){
    return this.http.get(this.baseUrl + '/api/event/' + id).pipe(map((data:Event) => {
      return data;
    }));
  }

  public delEvent(id: number)
  {
    return this.http.get(this.baseUrl + '/api/event/delete?id=' + id).pipe(map((data:any) => {
      return data;
    }));
  }

  public setEventCategory(event: EventCategory){
    return this.http.post(this.baseUrl + '/api/eventcategory', event).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getEventCategory(id: number){
    return this.http.get(this.baseUrl + '/api/eventcategory/' + id).pipe(map((data:EventCategory) => {
      return data;
    }));
  }

  public getEventCategories(id: number){
    return this.http.get(this.baseUrl + '/api/eventcategories?app_id=' + id).pipe(map((data:EventCategory[]) => {
      return data;
    }));
  }

  public delEventCategory(id: number)
  {
    return this.http.get(this.baseUrl + '/api/eventcategory/delete?id=' + id).pipe(map((data:any) => {
      return data;
    }));
  }

  public setEventUrl(event: EventUrl){
    return this.http.post(this.baseUrl + '/api/eventurl', event).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getEventUrl(id: number){
    return this.http.get(this.baseUrl + '/api/eventurl/' + id).pipe(map((data:EventUrl) => {
      return data;
    }));
  }

  public getEventUrls(id: number){
    return this.http.get(this.baseUrl + '/api/eventurls?app_id=' + id).pipe(map((data:EventUrl[]) => {
      return data;
    }));
  }

  public delEventUrl(id: number)
  {
    return this.http.get(this.baseUrl + '/api/eventurl/delete?id=' + id).pipe(map((data:any) => {
      return data;
    }));
  }

  public getTravelStations(lat: number, long: number, radius: number){
  
    return this.http.get(this.baseUrl + '/api/travel/nearby?lat=' + lat + '&long=' + long + "&radius=" + radius).pipe(map((data:TravelStation[]) => {
      return data;
    }));

  }

  public getCityQuizQuestion(id: number)
  {
    return this.http.get(this.baseUrl + '/api/cityquizquestion/' + id).pipe(map((data:CityQuizQuestion) => {
      return data;
    }));   
  }


  public setcityQuestion(question: CityQuizQuestion){
    return this.http.post(this.baseUrl + '/api/cityquizquestion', question).pipe(map((data:Response) => {
      return data;
    }));
  }

  public delcityQuizQuestion(id: number)
  {
    return this.http.get(this.baseUrl + '/api/cityquizquestion/delete?id=' + id).pipe(map((data:any) => {
      return data;
    }));
  }

  

  public getTodo(id: number){
    return this.http.get(this.baseUrl + '/api/todo/' + id).pipe(map((data:Todo) => {
      return data;
    }));
  }

  public getTodos(uid: number){
    return this.http.get(this.baseUrl + '/api/todos?uid=' + uid).pipe(map((data: Todo[]) => {
      return data;
    }));
  }

  public setTodo(todo: Todo){
    return this.http.post(this.baseUrl + '/api/todo', todo).pipe(map((data:Response) => {
      return data;
    }));
  }

  public delTodo(id: number)
  {
    return this.http.get(this.baseUrl + '/api/todo/delete?id=' + id).pipe(map((data:any) => {
      return data;
    }));
  }

  public getToday(id: number){
    return this.http.get(this.baseUrl + '/api/today/' + id).pipe(map((data:Today) => {
      return data;
    }));
  }

  public setToday(today: Today){
    return this.http.post(this.baseUrl + '/api/today', today).pipe(map((data:Response) => {
      return data;
    }));
  }


  public getFeedbacks(app_id: number)
  {
    return this.http.get(this.baseUrl + '/api/feedbacks?app_id=' + app_id).pipe(map((data:Feedback[]) => {
      return data;
    }));
  }

  public setFacebookToken(type: string, token_id: string, expires: number, token: string) {

    let postObject = {
      type: type,
      token_id: token_id,
      expires: expires,
      token: token
    }


    return this.http.post(this.baseUrl + '/api/facebook/token', postObject).pipe(map((data:any) => {
      return data;
    }));

  }

  public setFacebookID(customer_id: number, app_id: number, facebook_id: string, instagram_id: string) {

    let postObject = {
      customer_id: customer_id,
      app_id: app_id,
      facebook_id: facebook_id,
      instagram_id: instagram_id
    }

    return this.http.post(this.baseUrl + '/api/facebook/setId', postObject).pipe(map((data:any) => {
      return data;
    }));

  }

  public getAiAnswer(question: String)
  {

    let postObject = {
      question: question
    }

    return this.http.post(this.baseUrl + '/api/ai/get-answer', postObject).pipe(map((data:any) => {
      if (data.choices.length == 0)
      {
        alert(JSON.stringify(data));
        return "";
      }
        return data.choices[0].text;
    }));
  }


  public getDevicesPosition(apps: App[])
  {
    let appstr = "";

    apps.forEach(x => { appstr += x.id + ","; })

    appstr += "-1";

    return this.http.get(this.baseUrl + '/api/devices/positions?appstr=' + appstr).pipe(map((data:any) => {
      return data;
    }));

  }

  public uploadVideo(file){

    let formData = new FormData();
    formData.append('file', file);


    const url = this.baseUrl + '/api/uploadvideo?cid=' + this.data.currentUser.customer.id;
    return this.http.post(url, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  public getIncomingStatus()
  {
    return this.http.get(this.baseUrl + '/api/incoming/status?user_id=' + this.data.currentUser.id).pipe(map((data:any) => {
      return data;
    }));
  }

  public showError(error: HttpErrorResponse)
  {
    this.toastr.error(error.message, "Ett fel uppstod", { progressBar: true, closeButton: true });
  }
  public showSuccess(title: string, message: string)
  {
    this.toastr.success(message, title, { progressBar: true, closeButton: true });
  }

  public getDateStr(date_ob: Date)
  {
      let date = ("0" + date_ob.getDate()).slice(-2);

      // current month
      let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

      // current year
      let year = date_ob.getFullYear();

      // current hours
      let hours = date_ob.getHours();

      // current minutes
      let minutes = date_ob.getMinutes();

      // current seconds
      let seconds = date_ob.getSeconds();

      // prints date & time in YYYY-MM-DD HH:MM:SS format
      return year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds;
  }

}
