import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ApiService } from 'src/app/Services/api.service';
import { DataService } from 'src/app/Services/data.service';
import { App } from '../../../../../shared/models/app';
import { PortalText } from '../../../../../shared/models/portalText';
import { Response } from '../../../../../shared/models/response';

@Component({
  selector: 'app-portal-text-edit',
  templateUrl: './portal-text-edit.component.html',
  styleUrls: ['./portal-text-edit.component.scss']
})
export class PortalTextEditComponent implements OnInit {

  heading = 'Skapa portaltext';
  subheading = 'Här skapar du en portaltext.';
  icon = 'pe-7s-helm icon-gradient bg-malibu-beach';

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '5rem',
    minHeight: '2rem',
    placeholder: 'Skriv texten här...',
    translate: 'sv'
  };

  public text: PortalText = new PortalText();
  public apps: App[] = [];
  public fakeApp:App = new App();

  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {

      this.fakeApp.id = 0;
      this.fakeApp.name = 'Standard';

    this.api.getApps().subscribe((data: App[]) => {
      data.unshift(this.fakeApp);
      this.apps = data;
    });

    
    let id = +this.route.snapshot.paramMap.get("id");

    if (id != 0)
    {

      this.heading = 'Redigera';
      this.subheading = 'Här redigerar du en textsida';

      this.api.getPortalText(id).subscribe((data: PortalText) => {
        this.text = data;
      }, error => this.api.showError(error))
    }
    else
    {
      this.text.id = 0;
      this.text.app_id = 0;
      this.text.app = this.fakeApp;
    }

  }

  ngOnInit() {

  }



  save()
  {
    this.api.setPortalText(this.text).subscribe((data:Response)=>{
        this.text.id = data.id;
        this.api.showSuccess("Sparad", "Kunddatan sparades.");
    }, error => this.api.showError(error));
  }

  public delete()
  {
    if(confirm("Är du säker på att du vill ta bort den här sidan/länken?")) {

      this.api.delPortalText(this.text.id).subscribe((data:any) => {
        this.router.navigateByUrl('/portal/texts');
      });
    }
  }


}
