import { Media } from './media';
export class QuizQuestion {

    public id: number;
    public question: string;
    public media: Media;
    public typ: string;
    public answer: string;
    public answer1: string;
    public answer2: string;
    public answer3: string;
    public answer4: string;
    public answer5: string;
    public answer6: string;
    public answer7: string;
    public answer8: string;
    public answer9: string;
    public sort: number;
    public live: number;
    public correct: number;

    public answer1_number: number;
    public answer2_number: number;
    public answer3_number: number;
    public answer4_number: number;
    public answer5_number: number;
    public answer6_number: number;
    public answer7_number: number;
    public answer8_number: number;
    public answer9_number: number;
    public answer_total: number;


}

