import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-support-videos',
  templateUrl: './support-videos.component.html',
  styleUrls: ['./support-videos.component.scss']
})
export class SupportVideosComponent implements OnInit {

  slideConfig2 = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '0',
    slidesToShow: 1,
    speed: 500,
    dots: true,
  };

  constructor() { }

  ngOnInit() {
  }

}
