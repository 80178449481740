export class AdTag {

    public id: string|null = null;
    public value: string|null = null;
    public sort: number|null = null;
    public show: number|null = null;
    public procentage: number|null = null;
    public outcome: number|null = null;
    public status: number|null = null;

}

