import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Component, OnInit } from '@angular/core';
import { Page } from '../../../../../shared/models/page';
import { DataService } from 'src/app/Services/data.service';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Response } from '../../../../../shared/models/response';

@Component({
  selector: 'app-page-edit',
  templateUrl: './page-edit.component.html',
  styleUrls: ['./page-edit.component.scss']
})
export class PageEditComponent implements OnInit {

  heading = 'Skapa undersida/länk';
  subheading = 'Här skapar du en textsida eller en länk som syns på profilsidan.';
  icon = 'pe-7s-id icon-gradient bg-malibu-beach';

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '5rem',
    minHeight: '2rem',
    placeholder: 'Skriv texten här...',
    translate: 'no'
  };

  public page: Page = new Page();
  public pages: Page[] = [];

  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {

      this.api.getPages(this.data.currentUser.customer.id).subscribe((data: Page[]) => {

        this.pages = data;

      });

    let id = +this.route.snapshot.paramMap.get("id");

    if (id != 0)
    {

      this.heading = 'Redigera undersida';
      this.subheading = 'Här redigerar du en undersida';

      this.api.getPage(id).subscribe((data: Page) => {
        this.page = data;
      }, error => this.api.showError(error))
    }
    else
    {
      this.page.id = 0;
      this.page.typ = "textsida";
      this.page.customer_id = this.data.currentUser.customer.id;
    }

  }

  ngOnInit() {

  }



  save()
  {
    this.api.setPage(this.page).subscribe((data:Response)=>{
        this.page.id = data.id;
        this.api.showSuccess("Sparad", "Kunddatan sparades.");
    }, error => this.api.showError(error));
  }

  public delete()
  {
    if(confirm("Är du säker på att du vill ta bort den här sidan/länken?")) {

      this.api.delPage(this.page.id).subscribe((data:any) => {
        this.router.navigateByUrl('/pages');
      });
    }
  }

  public new_page()
  {
    this.page.pages.push(new Page);
  }


}
