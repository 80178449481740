import { App } from './../../../../../shared/models/app';
import { Component, OnInit } from '@angular/core';
import { Customer } from '../../../../../shared/models/customer';
import { DataService } from 'src/app/Services/data.service';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Response } from '../../../../../shared/models/response';

@Component({
  selector: 'app-sales-timeline',
  templateUrl: './sales-timeline.component.html',
  styleUrls: ['./sales-timeline.component.scss']
})
export class SalesTimelineComponent implements OnInit {

  heading = 'Avtal';
  subheading = 'Tidslinje';
  icon = 'pe-7s-note icon-gradient bg-arielle-smile';

  public apps: App[];
  public selectedApps: App[] = [];
  public total = 0;
  public timestart: Date;
  public timestop: Date;
  public timeline: any[] = [];


  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {

      if (this.data.timeline_start)
      {
        this.timestart = this.data.timeline_start;
      } else {
        this.timestart = new Date("2018-01-01");
      }

      if (this.data.timeline_start)
      {
        this.timestop = this.data.timeline_stop;
      } else {
        this.timestop = new Date("2020-06-01");
      }

      this.apps = this.data.currentUser.apps;
      this.selectedApps = this.data.currentUser.selectedApps;

      this.change();


  }

  ngOnInit() {

  }

  change()
  {

    this.data.currentUser.selectedApps = this.selectedApps;
    this.data.timeline_stop = this.timestop;
    this.data.timeline_start = this.timestart;

    this.api.getSalesStat(this.selectedApps, this.timestart.toISOString(), this.timestop.toISOString()).subscribe((data: any[]) => {

      this.timeline = data;

    })
  }

  open(customer_id: number, contract_id)
  {
    this.api.getCustomer(customer_id).subscribe((data: Customer) => {
      this.data.currentUser.customer = data;
      this.api.setUser(this.data.currentUser).subscribe((data: Response) => {
        sessionStorage.setItem("url", "/contract/" + contract_id);
        this.router.navigateByUrl('/login');
      })

    })


  }



}
