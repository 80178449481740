import { Component, OnInit } from '@angular/core';
import { Device } from '../../../../../shared/models/device';
import { DataService } from 'src/app/Services/data.service';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-follower-single',
  templateUrl: './follower-single.component.html',
  styleUrls: ['./follower-single.component.scss']
})
export class FollowerSingleComponent implements OnInit {

  heading = 'Följare';
  subheading = '';
  icon = 'pe-7s-note icon-gradient bg-arielle-smile';

  public device: Device;

  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {
    let id = +this.route.snapshot.paramMap.get("id");

      this.api.getFollower(id).subscribe((data: Device) => {
        this.device = data;

        this.heading = "Följare: Enhet#" + this.device.id;

      })


  }

  ngOnInit() {


  }




}
