import { Customer } from "./customer";
import { User } from "./user";

export class CustomerLog {

    public id: number|null = null;;
    public user_id: number|null = null;;
    public user: User|null = null;;
    public customer_id: number|null = null;;
    public customer: Customer|null = null;;
    public msg: string|null = null;;
    public type: string|null = null;;
    public time: string|null = null;;

}

