import { Quiz } from './../../../../../shared/models/quiz';
import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/Services/data.service';
import { HttpClient } from '@angular/common/http';
import { DataTablesResponse } from '../../../../../shared/models/datatabelsResponse';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-quizzes',
  templateUrl: './quizzes.component.html',
  styleUrls: ['./quizzes.component.scss']
})
export class QuizzesComponent implements OnInit {
  baseUrl:string = environment.apiUrl;
  heading = 'Alla quiz';
  subheading = 'Välj Redigera för att ändra quiz';
  icon = 'pe-7s-medal icon-gradient bg-malibu-beach';

  public quizzes: Quiz[] = [];

  dtOptions: DataTables.Settings = {};

  constructor(private data: DataService, private http: HttpClient) {
  }

  ngOnInit(): void {
    const that = this;

    this.dtOptions = {
      order: [[ 0, "desc" ]],
      pagingType: 'full_numbers',
      pageLength: 25,
      serverSide: true,
      processing: true,
      language: {
        "emptyTable":     "Tabellen innehåller ingen data",
        "info":           "Visar rad _START_ - _END_ av _TOTAL_ rader",
        "infoEmpty":      "Visar 0 av 0 rader",
        "infoFiltered":   "(av totalt _MAX_ rader)",
        "infoPostFix":    "",
        "thousands":      ",",
        "lengthMenu":     "Visa _MENU_ rader per sida",
        "loadingRecords": "Laddar...",
        "processing":     "Laddar...",
        "search":         "Sök:",
        "zeroRecords":    "Inget innehåll hittat",
        "paginate": {
          "first":      "Första",
          "last":       "Sista",
          "next":       "Nästa",
          "previous":   "Föregående"
        },
        "aria": {
            "sortAscending":  ": activate to sort column ascending",
            "sortDescending": ": activate to sort column descending"
        }
    },
      ajax: (dataTablesParameters: any, callback) => {
        that.http.post<DataTablesResponse>(
            this.baseUrl + '/api/quizzes?customer_id=' + this.data.currentUser.customer.id,
            dataTablesParameters, {}
        ).subscribe(resp => {
          that.quizzes = resp.data;

          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
      },
      columns: [{ data: 'id' }, { data: 'name' }, { data: 'typ' }, { data: 'starttime' }, { data: 'stoptime' }]
    };
  }

  ngOnDestroy(): void {
  }
}
