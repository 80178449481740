import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { Template } from '../../../../../shared/models/template';
import { DataService } from 'src/app/Services/data.service';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Response } from '../../../../../shared/models/response';
import { DOCUMENT } from '@angular/common';
import { Ad } from '../../../../../shared/models/ad';

@Component({
  selector: 'app-admin-template-edit',
  templateUrl: './admin-template-edit.component.html',
  styleUrls: ['./admin-template-edit.component.scss']
})
export class AdminTemplateEditComponent implements OnInit {

  heading = 'Skapa införandemall';
  subheading = 'Här skapar du nya införandemallar';
  icon = 'pe-7s-up-arrow icon-gradient bg-malibu-beach';

  public template: Template = new Template();
  public preview_str: string;

  editorOptions = {theme: 'vs', language: 'scss'};
  @ViewChild('preview_card') public preview_card: ElementRef;
  @ViewChild('preview') public previewArea: ElementRef;

  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router, @Inject(DOCUMENT) document) {
    let id = +this.route.snapshot.paramMap.get("id");

    if (id != 0)
    {

      this.heading = 'Redigera mallen';
      this.subheading = 'Här redigerar du en mall';

      this.api.getTemplate(id).subscribe((data: Template) => {
        this.template = data;

        this.preview();

      })
    }
    else
    {
      this.template.id = 0;
    }

  }

  ngOnInit() {

  }

  save()
  {
    this.api.setTemplate(this.template).subscribe((data:Response)=>{
        this.template.id = data.id;
        this.api.showSuccess("Sparad", "Mallen sparades");
    }, error => this.api.showError(error));
    this.preview();
  }

  preview()
  {
    this.api.getAd(this.template.ad_id).subscribe((data: Ad) => {

      this.api.previewAd(data).subscribe((data:any)=>{
        if (data.feed_template_id == this.template.id)
        {
          this.preview_str = data.feed;

          if (!document.getElementById("template_css_" + data.feed_template_id))
          { $("head").append('<style id="template_css_' + data.feed_template_id + '" type="text/css">' + data.feed_css + '</style>'); }
          else
          { $("#template_css_" + data.feed_template_id).text(data.feed_css); }

        }
        else
        {
          this.preview_str = data.full;

          if (!document.getElementById("template_css_" + data.full_template_id))
          { $("head").append('<style id="template_css_' + data.full_template_id + '" type="text/css">' + data.full_css + '</style>'); }
          else
          { $("#template_css_" + data.full_template_id).text(data.full_css); }

        }

        let width = this.preview_card.nativeElement.offsetWidth - 40;
        let zoom = width / 1000;
        this.previewArea.nativeElement.style.zoom = zoom ;

        setTimeout( function() {

        var timeElements = document.getElementsByClassName('ad_time');
        for(var i = 0; i < timeElements.length; i++)
        {
          let time = new Date(timeElements[i].getAttribute('datetime'));
          //@ts-ignore
          timeElements[i].innerText = TimeAgo.inWords(time.getTime());
        }

        var timeElements = document.getElementsByClassName('ad_time2');
        for(var i = 0; i < timeElements.length; i++)
        {
          let time = new Date(timeElements[i].getAttribute('datetime'));
          //@ts-ignore
          timeElements[i].innerText = time.toLocaleString("sv");
        }

        //@ts-ignore
        var slider = tns({ container: '.bd_slider',
        items: 1,
        autoHeight: false,
        controls: false,
        nav: false,
        speed: 400,
        autoplay: true,
        mouseDrag: true });



        },1);
      });

    });

  }

}
