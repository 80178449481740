import { Customer } from './customer';
import { Media } from './media';
import { ProductCategory } from './productCategory';
export class Product {

    public id: number;
    public title: String;
    public txt: String;
    public price: number;
    public customer_id: Number;
    public customer: Customer;
    public customer_str: String;
    public categories: ProductCategory[];
    public medias: Media[] = [];
    public status: Number;
    public amount: number;

}

