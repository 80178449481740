import { Component, OnInit, OnDestroy } from '@angular/core';
import { Ad } from '../../../../../shared/models/ad';
import { DataService } from 'src/app/Services/data.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'app-ads',
  templateUrl: './ads.component.html',
  styleUrls: ['./ads.component.sass']
})
export class AdsComponent implements OnInit, OnDestroy {
  baseUrl:string = environment.apiUrl;
  heading = 'Annonser';
  subheading = 'Förändingen inom parentesen är jämför med medel det senaste kvartalet';
  icon = 'pe-7s-speaker icon-gradient bg-arielle-smile';

  public ads: Ad[] = [];

  dtOptions: DataTables.Settings = {};

  constructor(private data: DataService, private http: HttpClient) {
  }

  ngOnInit(): void {
    const that = this;

    this.dtOptions = {
      order: [[ 1, "desc" ]],
      pagingType: 'full_numbers',
      pageLength: 25,
      serverSide: true,
      processing: true,
      language: {
        "emptyTable":     "Det finns ingen data i tabellen",
        "info":           "Visar rad _START_ - _END_ av _TOTAL_ rader",
        "infoEmpty":      "Visar 0 av 0 rader",
        "infoFiltered":   "(av totalt _MAX_ rader)",
        "infoPostFix":    "",
        "thousands":      ",",
        "lengthMenu":     "Visa _MENU_ rader per sida",
        "loadingRecords": "Laddar...",
        "processing":     "Laddar...",
        "search":         "Sök:",
        "zeroRecords":    "Inget innehåll hittat",
        "paginate": {
            "first":      "Första",
            "last":       "Sista",
            "next":       "Nästa",
            "previous":   "Föregående"
        },
        "aria": {
            "sortAscending":  ": activate to sort column ascending",
            "sortDescending": ": activate to sort column descending"
        }
    },
      ajax: (dataTablesParameters: any, callback) => {
        that.http
          .post<DataTablesResponse>(
            this.baseUrl + '/api/ads?customer_id=' + this.data.currentUser.customer.id,
            dataTablesParameters, {}
          ).subscribe(resp => {

            that.ads = resp.data;

            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
      columns: [{ data: 'title' }, { data: 'timestart' }, { data: 'timestop' }, { data: 'status' }]
    };

  }

  ngOnDestroy(): void {
  }
}
