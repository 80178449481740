import { Component, OnInit } from '@angular/core';
import { App } from '../../../../../shared/models/app';
import { DataService } from 'src/app/Services/data.service';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Response } from '../../../../../shared/models/response';
import { Place } from '../../../../../shared/models/place';
import { environment } from 'src/environments/environment';
import { TravelStation } from '../../../../../shared/models/travelStation';

@Component({
  selector: 'app-admin-apps-edit',
  templateUrl: './admin-apps-edit.component.html',
  styleUrls: ['./admin-apps-edit.component.scss']
})
export class AdminAppsEditComponent implements OnInit {

  baseUrl:string = environment.apiUrl;
  heading = 'Skapa app';
  subheading = 'Här skapar du en ny app';
  icon = 'pe-7s-phone icon-gradient bg-malibu-beach';

  public app: App = new App();
  public places: Place[] = [];
  public apps: App[] = [];
  public travelStations: TravelStation[] = [];

  public pages: any[] = [];

  editorOptions = {theme: 'vs', language: 'html'};

  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {
    let id = +this.route.snapshot.paramMap.get("id");

    if (id != 0)
    {

      this.heading = 'Redigera app';
      this.subheading = 'Här redigerar du en app';

      this.api.getApp(id).subscribe((data: App) => {
        this.app = data;
      }, error => this.api.showError(error))
    }
    else
    {
      this.app.id = 0;
    }

  }

  ngOnInit() {
    this.api.getPlaces().subscribe((data: Place[]) => {
      this.places = data;
    });
    this.api.getApps().subscribe((data: App[]) => {
      this.apps = data;
    })
  }


  onUploadChangeIcon(evt: any) {
    const file = evt.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = this.handleReaderLoadedIcon.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  handleReaderLoadedIcon(e) {
    let img_str = btoa(e.target.result);
    this.api.setAppIcon(this.app.id, img_str, "icon").subscribe((data: Response) => {
      this.api.showSuccess("Uppladdad", "Ikonen sparades");
      this.api.getApp(this.app.id).subscribe((data: App) => {
        this.app = data;
      }, error => this.api.showError(error));
    }, error => this.api.showError(error))
  }

  onUploadChangeSplash(evt: any) {
    const file = evt.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = this.handleReaderLoadedSplash.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  handleReaderLoadedSplash(e) {
    let img_str = btoa(e.target.result);
    this.api.setAppIcon(this.app.id, img_str, "splash").subscribe((data: Response) => {
      this.api.showSuccess("Uppladdad", "Splashbilden sparades");
      this.api.getApp(this.app.id).subscribe((data: App) => {
        this.app = data;
      }, error => this.api.showError(error));
    }, error => this.api.showError(error))
  }

  onUploadChangeAndroidIcon(evt: any) {
    const file = evt.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = this.handleReaderLoadedAndroidIcon.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  handleReaderLoadedAndroidIcon(e) {
    let img_str = btoa(e.target.result);
    this.api.setAppIcon(this.app.id, img_str, "androidicon").subscribe((data: Response) => {
      this.api.showSuccess("Uppladdad", "Splashbilden sparades");
      this.api.getApp(this.app.id).subscribe((data: App) => {
        this.app = data;
      }, error => this.api.showError(error));
    }, error => this.api.showError(error))
  }

  onUploadChangeAndroidBackground(evt: any) {
    const file = evt.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = this.handleReaderLoadedAndroidBackground.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  handleReaderLoadedAndroidBackground(e) {
    let img_str = btoa(e.target.result);
    this.api.setAppIcon(this.app.id, img_str, "androidbackground").subscribe((data: Response) => {
      this.api.showSuccess("Uppladdad", "Splashbilden sparades");
      this.api.getApp(this.app.id).subscribe((data: App) => {
        this.app = data;
      }, error => this.api.showError(error));
    }, error => this.api.showError(error))
  } 

  onUploadChangeHeader(evt: any) {
    const file = evt.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = this.handleReaderLoadedHeader.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  handleReaderLoadedHeader(e) {
    let img_str = btoa(e.target.result);
    this.api.setAppIcon(this.app.id, img_str, "headerimage").subscribe((data: Response) => {
      this.api.showSuccess("Uppladdad", "Headerbild sparades");
      this.api.getApp(this.app.id).subscribe((data: App) => {
        this.app = data;
      }, error => this.api.showError(error));
    }, error => this.api.showError(error))
  } 


  save()
  {
    this.api.setApp(this.app).subscribe((data:Response)=>{
      this.app.id = data.id;
      this.api.showSuccess("Sparad", "Datan sparades");
    });
  }

  getTravelStations() {

    this.app.places.forEach(place => { 

      this.api.getTravelStations(place.latitude, place.longitude, 10000).subscribe((data: TravelStation[]) => {
        
        data.forEach(travelStation => {

          if(!this.travelStations.some(x => x.id == travelStation.id))
          {
            this.travelStations.push(travelStation);
          }

        })

      });


    });

  }

  addStation(id, name)
  {

    let stations = JSON.parse(this.app.station_str);

    let item = { id: id, name: name };

    stations.push(item);

    this.app.station_str = JSON.stringify(stations, null, 4);

  }

  removeStation(id)
  {

    let stations = JSON.parse(this.app.station_str);  
    let index = stations.findIndex(x => x.id == id);
    if (index != -1)
    {
      stations.splice(index, 1);
    }
    this.app.station_str = JSON.stringify(stations, null, 4);

  }

  connect_fb()
  {

    this.data.fb.api('/me/accounts?limit=500&fields=instagram_business_account{id,name,username,profile_picture_url},id,name,access_token,username,profile_picture_url').then((res: any) => {

      for(let i = 0; i < res.data.length; i++)
      {

        res.data[i].displayName = res.data[i].name;

        if (res.data[i].instagram_business_account)
        {
          res.data[i].displayName += " (Facebook + Instagram)";
        }
        else
        {
          res.data[i].displayName += " (Facebook)";
        }
      }

      this.pages = res.data;


    })

  }

  onChangeFacebook(event)
  {

    let page = this.pages.find(x => x.id == event.id);
    let instagram_id = null;
    if (page.instagram_business_account)
    {
      instagram_id = page.instagram_business_account.id;
    }

    this.app.facebook_id = page.id;
    this.app.instagram_id = instagram_id;


    this.data.fb.api('/oauth/access_token?grant_type=fb_exchange_token&client_id=1646608665638879&client_secret=33fe57a388643f82424957072ce0ef00&fb_exchange_token=' + page.access_token, "get").then((res: any) => {

      let access_token = res.access_token;

      this.data.fb.api('/oauth/access_token?grant_type=fb_attenuate_token&client_id=1646608665638879&fb_exchange_token=' + access_token, "get").then((res: any) => {


        this.data.fb.api('/debug_token?input_token=' + res.access_token + "&access_token=" + access_token, "get").then((res: any) => {

          console.log(res);

          let expired = res.data.data_access_expires_at;

          this.api.setFacebookToken("page", page.id, expired, access_token).subscribe((data: Response) => {
                
              this.api.showSuccess("Sparad!", "Facebooksidans token är nu uppdaterat.");

          });
      })
    })
  });



  }


}
