import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Ad } from '../../../../../shared/models/ad';
import { ApiService } from 'src/app/Services/api.service';
import { DataService } from 'src/app/Services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AdLog } from '../../../../../shared/models/adLog';
import { Response } from '../../../../../shared/models/response';

@Component({
  selector: 'app-external-proof-reading',
  templateUrl: './external-proof-reading.component.html',
  styleUrls: ['./external-proof-reading.component.scss']
})
export class ExternalProofReadingComponent implements OnInit {

  @ViewChild('news_preview2') public news_preview2: ElementRef;
  @ViewChild('preview_card') public preview_card: ElementRef;

  public ad: Ad = new Ad();
  public preview_bodystr: string = "";
  public status = 0;
  public sent = 0;
  public adlog: AdLog = new AdLog();


  constructor(public data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router, private toastr: ToastrService) { }

  ngOnInit(): void {

    let id = +this.route.snapshot.paramMap.get("id");

    this.api.getAd(id).subscribe((data: Ad) => {

      this.ad = data;

      if (this.ad.status == 1 && new Date(this.ad.timestart) > new Date())
      {
        this.status = 1;
      }
      else if (this.ad.status == 1 && new Date(this.ad.timestart) < new Date())
      {
        this.status = 2;
      }

      this.api.previewAd(this.ad).subscribe((data:any)=> {
        this.preview_bodystr = data.full;
  
        if (!document.getElementById("template_css_" + data.feed_template_id)) { 
          $("head").append('<style id="template_css_' + data.feed_template_id + '" type="text/css">' + data.feed_css + '</style>'); 
        }
        else {
          $("#template_css_" + data.feed_template_id).text(data.feed_css); 
        }
  
        if (!document.getElementById("template_css_" + data.full_template_id)) { 
          $("head").append('<style id="template_css_' + data.full_template_id + '" type="text/css">' + data.full_css + '</style>'); 
        }
        else { 
          $("#template_css_" + data.full_template_id).text(data.full_css); 
        }
  
        let width = this.preview_card.nativeElement.offsetWidth - 40;
        let zoom = width / 1000;
        this.news_preview2.nativeElement.style.zoom = zoom ;
  
        setTimeout( function() {
          var timeElements = document.getElementsByClassName('ad_time');
          for(var i = 0; i < timeElements.length; i++) {
            let time = new Date(timeElements[i].getAttribute('datetime'));
            //@ts-ignore
            timeElements[i].innerText = TimeAgo.inWords(time.getTime());
          }
  
        //@ts-ignore
        var slider = tns({ container: '.bd_slider',
        items: 1,
        autoHeight: false,
        controls: false,
        nav: false,
        speed: 400,
        autoplay: true,
        mouseDrag: true });
        },1);
      }, error => this.api.showError(error));


    })


  }

  add_comment()
  {

    this.sent = -1;
    this.adlog.ad_id = this.ad.id;
    this.adlog.type = "comment";

    this.api.setAdLog(this.adlog).subscribe((data: Response) => {
      this.sent = 1;
    });

  

  }

}
