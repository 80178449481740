import { Component, OnInit } from '@angular/core';
import { Section } from '../../../../../shared/models/section';
import { App } from '../../../../../shared/models/app';
import { DataService } from 'src/app/Services/data.service';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Response } from '../../../../../shared/models/response';

@Component({
  selector: 'app-admin-section-edit',
  templateUrl: './admin-section-edit.component.html',
  styleUrls: ['./admin-section-edit.component.scss']
})
export class AdminSectionEditComponent implements OnInit {

  heading = 'Skapa sektion';
  subheading = 'Här skapar du en ny sektion';
  icon = 'pe-7s-server icon-gradient bg-malibu-beach';

  public section: Section = new Section(0, '');
  public apps: App[] = [];

  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {
    let id = +this.route.snapshot.paramMap.get("id");
    
    this.api.getApps().subscribe((data: App[]) => {
      this.apps = data;
    })

    if (id != 0)
    {

      this.heading = 'Redigera sektion';
      this.subheading = 'Här redigerar du en sektion.';

      this.api.getSection(id).subscribe((data: Section) => {
        this.section = data;
      })
    }
    else
    {
      this.section.id = 0;
    }

  }

  ngOnInit() {
    this.apps = this.data.currentUser.customer.apps;
  }

 

  save()
  {
    this.api.setSection(this.section).subscribe((data:Response)=>{
      if (this.section.id == 0)
      {
        this.router.navigateByUrl('/section/' + data.id);
      }
    });
  }

}