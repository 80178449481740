export class LiveEventEvent
 {
    public id: number;
    public eventID: number;
    public date: String;
    public updateType: number;
    public updateText: String;
    public title: String;
    public scoreTeamOneUpdate: number;
    public scoreTeamTwoUpdate: number;

}

