import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/Services/data.service';

@Component({
  selector: 'app-support-customer-events',
  templateUrl: './support-customer-events.component.html',
  styleUrls: ['./support-customer-events.component.scss']
})
export class SupportCustomerEventsComponent implements OnInit {

  heading = 'Händelser';
  subheading = 'Här ser du händelser för den givna kunden';
  icon = 'pe-7s-network icon-gradient bg-malibu-beach';


  dtOptions: DataTables.Settings = {};

  constructor(private data: DataService, private http: HttpClient) {
  }

  ngOnInit(): void {



  }
}
