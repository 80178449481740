import { Component, OnInit } from '@angular/core';
import { App } from '../../../../../shared/models/app';
import { ApiService } from 'src/app/Services/api.service';
import { DataService } from 'src/app/Services/data.service';

@Component({
  selector: 'app-admin-devices-position',
  templateUrl: './admin-devices-position.component.html',
  styleUrls: ['./admin-devices-position.component.scss']
})
export class AdminDevicesPositionComponent implements OnInit {

  heading = 'Skapa införandemall';
  subheading = 'Här skapar du nya införandemallar';
  icon = 'pe-7s-up-arrow icon-gradient bg-malibu-beach';

  public apps: App[];
  public selectedApps: App[] = [];

  public devices: any[] = [];

  constructor(private data: DataService, private api: ApiService) {

    this.apps = this.data.currentUser.apps;
    this.selectedApps = this.data.currentUser.selectedApps;

    this.change();

  }

  ngOnInit() {
  }

  change()
  {
    this.data.currentUser.selectedApps = this.selectedApps;

    if (this.selectedApps && this.selectedApps.length > 0)
    {
      this.api.getDevicesPosition(this.selectedApps).subscribe(data => {
        this.devices = data;
      })
    }


  }

}
