import { DefaultLunch } from './../../../../../shared/models/defaultlunch';
import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/Services/data.service';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Response } from '../../../../../shared/models/response';

@Component({
  selector: 'app-default-lunch-edit',
  templateUrl: './default-lunch-edit.component.html',
  styleUrls: ['./default-lunch-edit.component.scss']
})
export class DefaultLunchEditComponent implements OnInit {
  heading = 'Skapa standardlunchrad';
  subheading = 'Här skapar du en standardlunchrad.';
  icon = 'pe-7s-id icon-gradient bg-malibu-beach';

  public default_lunch: DefaultLunch = new DefaultLunch();

  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {

    let id = +this.route.snapshot.paramMap.get("id");

    if (id != 0)
    {

      this.heading = 'Redigera standardlunchrad';
      this.subheading = 'Här redigerar du en standardlunchrad';

      this.api.getDefaultLunch(id).subscribe((data: DefaultLunch) => {
        this.default_lunch = data;
      }, error => this.api.showError(error))
    }
    else
    {
      this.default_lunch.id = 0;
      this.default_lunch.status = 1;
      this.default_lunch.customer_id = this.data.currentUser.customer.id;
    }

  }

  ngOnInit() {

  }



  save()
  {
    this.api.setDefaultLunch(this.default_lunch).subscribe((data:Response)=>{
        this.default_lunch.id = data.id;
        this.api.showSuccess("Sparad", "Standardlunchen sparades.");
    }, error => this.api.showError(error));
  }

  public delete()
  {
    if(confirm("Är du säker på att du vill ta bort den här standardlunchen?")) {

      this.api.delDefaultLunch(this.default_lunch.id).subscribe((data:any) => {
        this.router.navigateByUrl('/defaultlunches');
      });
    }
  }

}

