import { App } from "./app";

export class PortalText {

    public id: number;
    public code: string;
    public txt: string;
    public title: string;
    public app: App;
    public app_id: number;

}

