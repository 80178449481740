import { Customer } from './customer';
import { App } from './app';

export class Contract {

    public id: number;
    public customer: Customer;
    public startdate: string;
    public enddate: string;
    public pdf_url: string;
    public img_url: string;
    public monthlycost: number;
    public startcost: number;
    public invoicetype: string;
    public invoice_name: string;
    public invoice_co: string;
    public invoice_postaddress: string;
    public invoice_postnumber: string;
    public invoice_postcity: string;
    public invoice_email: string;
    public contact_person: string;
    public contact_email: string;
    public contact_phone: string;
    public orgnr: string;
    public apps: App[] = [];

}

