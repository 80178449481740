import { AdTag } from './adtag';
import { App } from './app';
import { Media } from './media';
import { Serie } from './serie';
import { Byline } from './byline';
import { Template } from './template';
import { User } from './user';
import { Summary } from './summary';
import { AdLog } from './adLog';

export class Ad {

    public id: number|null = null;
    public title: String|null = null;
    public alt_title: String|null = null;
    public status: number|null = null;
    public body: String|null = null;
    public template: number|null = null;
    
    public click: number|null = null;
    public views: number|null = null;
    public diff: number|null = null;
    public unique_views: number|null = null;
    public shortbody: String|null = null;
    public timestart: string|null = null;
    public timestop: string|null = null;
    public timeupdate: String|null = null;
    public preword: String|null = null;
    public customer_id: number|null = null;
    public category: String|null = null;
    public apps: App[] = [];
    public medias: Media[] = [];
    public series: Serie[] = [];
    public byline: Byline|null = null;
    public byline_name: string|null = null;
    public isNewsAd: string|null = null;
    public isWorkAd: string|null = null;
    public isBooliAd: string|null = null;
    public isMenu1Ad: string|null = null;
    public isMenu2Ad: string|null = null;
    public isTakeOverAd: string|null = null;
    public isProfileAd: string|null = null;
    public isRandomOrder: string|null = null;
    public isPositionAd: string|null = null;
    public positionTitle: string|null = null;
    public positionText: string|null = null;
    public isFree: string|null = null;
    public externallink: string|null = null;
    public reactivates: any[] = [];
    public clicked: string|null = null;
    public vinjett: string|null = null;
    public notify: string|null = null;
    public notifyAll: string|null = null;
    public isStudentOffer: string|null = null;
    public isOffer: string|null = null;
    public user_id: number|null = null;
    public template_full: Template|null = null;
    public template_feed: Template|null = null;
    public tags: AdTag[] = [];
    public columnist: User|null = null;
    public columnist_id: number|null = null;
    public link_base: string|null = null;
    public link_id: number|null = null;
    public isSummary: number|null = null;
    public links: string[] = [];
    public summaries: Summary[] = [];

    public ad_logs: AdLog[] = [];

    public shareOnFacebook: string|null = null;
    public shareOnInstagram: string|null = null;

    public releatedAds: Ad[] = [];
    public distance: number|null = null;

    public facts_title: string|null = null;
    public facts_txt: string|null = null;

    public ad_type: string|null = null;

    public facebook_text: string|null = null;
    public instagram_text: string|null = null;

    public prio: number|null = null;

    public from_name: string|null = null;
    public from_email: string|null = null;
    public from_phone: string|null = null;	

    public identifier: string|null = null;


}

