import { App } from './app';

export class Section {

    public id: number|null = null;
    public name: string|null = null;
    public app: App|null = null;

    constructor(_id: number|null, _name: string|null)
    {
        this.id = _id;
        this.name = _name;
    }

}

