import { GiftCardLog } from './giftcardEvent';

export class GiftCard {

    public id: number;
    public code: string;
    public amount: number;
    public amount_left: number;
    public expire: string;
    public logs: GiftCardLog[] = [];

}

