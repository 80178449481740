import { Media } from './media';
import { ProfilePlace } from './profile_place';

export class Profile {

    public id: number|null = null;
    public name: string|null = null;
    public icon: string|null = null;
    public profile_img: String|null = null;
    public profile_imgs: Media[]|null = null;
    public profile_places: ProfilePlace[]|null = null;
    public profile_txt: string|null = null;
    public profile_openhours: string|null = null;
    public profile_address: string|null = null;
    public profile_longitude: string|null = null;
    public profile_latitude: string|null = null;
    public profile_phone: string|null = null;
    public profile_email: string|null = null;
    public profile_webb: string|null = null;
    public profile_contact_1_name: string|null = null;
    public profile_contact_1_email: string|null = null;
    public profile_contact_1_title: string|null = null;
    public profile_contact_1_phone: string|null = null;
    public profile_contact_2_name: string|null = null;
    public profile_contact_2_email: string|null = null;
    public profile_contact_2_title: string|null = null;
    public profile_contact_2_phone: string|null = null;
    public profile_contact_3_name: string|null = null;
    public profile_contact_3_email: string|null = null;
    public profile_contact_3_title: string|null = null;
    public profile_contact_3_phone: string|null = null;

}

