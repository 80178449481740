import { Media } from './media';
import { Customer } from './customer';
import { App } from './app';
export class CityQuizQuestion {

    public id: number;
    public question: string;
    public medias: Media[];
    public customer: Customer;
    public customer_id: number;
    public apps: App[];
    public alternative1: string;
    public alternative2: string;
    public alternative3: string;
    public alternative4: string;
    public answer: string;
    public status: number;
    public type: number;

}

