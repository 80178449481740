import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DataService } from 'src/app/Services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';

@Component({
  selector: 'app-video-upload',
  templateUrl: './video-upload.component.html',
  styleUrls: ['./video-upload.component.scss']
})
export class VideoUploadComponent implements OnInit {

  heading = 'Ladda upp video';
  subheading = '';
  icon = 'pe-7s-video icon-gradient bg-arielle-smile';

  public statdata: any;
  public loaded:boolean = false;
  public progress = { loaded : 0 , total : 0 };

  constructor(public data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {
  }

  @ViewChild('selectfile') el:ElementRef;   //in html we make variable of selectfile



  ngOnInit() {

  }

  public uploadFile(file) {
    var filedata = this.el.nativeElement.files[0];

    this.api.uploadVideo(filedata).subscribe((data:any) => {

      if(data.type == 1 && data.loaded && data.total){
        this.progress.loaded = data.loaded;
        this.progress.total = data.total;
      }
      else if(data.body){
        console.log(data.body);
      }

    });

  }

}
