import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/Services/data.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { Todo } from '../../../../../shared/models/todo';
import { ApiService } from 'src/app/Services/api.service';
import { User } from '../../../../../shared/models/user';
import { Router } from '@angular/router';
import { Response } from '../../../../../shared/models/response';

@Component({
  selector: 'app-intranet-start',
  templateUrl: './intranet-start.component.html',
  styleUrls: ['./intranet-start.component.scss']
})
export class IntranetStartComponent implements OnInit {

  heading = 'God morgon';
  subheading = 'Powermarketings intranät';
  icon = 'pe-7s-users icon-gradient bg-arielle-smile';

  todos: Todo[] = [];

  overdue: Todo[] = [];
  today: Todo[] = [];
  tomorrow: Todo[] = [];
  someday: Todo[] = [];

  todo: Todo = new Todo;
  newtodo: Todo = new Todo;
  public employees: User[] = [];

  constructor(public data: DataService, public api: ApiService, private router: Router) {

    let d = new Date();

    if (d.getHours() < 25) { this.heading = "Du jobbar sent"; }
    if (d.getHours() < 23) { this.heading = "God natt"; }
    if (d.getHours() < 18) { this.heading = "God eftermiddag"; }
    if (d.getHours() < 13) { this.heading = "Dags för lunch"; }
    if (d.getHours() < 12) { this.heading = "God förmiddag"; }
    if (d.getHours() < 9) { this.heading = "God morgon"; }
    if (d.getHours() < 6) { this.heading = "Du jobbar tidigt"; }

    this.heading += " " + data.currentUser.name;

  }

  ngOnInit(): void {

    this.updatetodo();

    this.newtodo.users.push(this.data.currentUser);

    this.api.getEmployees().subscribe((data: User[]) => {
      this.employees = data;
    })

  }


  updatetodo()
  {

    this.api.getTodos(this.data.currentUser.id).subscribe((data: Todo[]) => {

      this.todos = data;

      var start = new Date();
      start.setHours(0,0,0,0);

      let d = new Date();
      let todaystr = d.toDateString();
      let d2 = d.setDate(d.getDate() + 1);
      let tomorrowstr = d.toDateString();

      this.today = [];
      this.tomorrow = [];
      this.someday = [];

      data.forEach(item => {

        let id = new Date(Date.parse(item.duedate));
        let datestr = id.toDateString();


          if (id < start)
          {
              this.overdue.push(item)
          }
          else if (datestr == todaystr)
          {
            this.today.push(item);
          }
          else if (datestr == tomorrowstr)
          {
            this.tomorrow.push(item);
          }
          else
          {
            this.someday.push(item);
          }

      });

    });

  }

  create(nr: number)
  {
      this.todo = new Todo;
      let d = new Date();
      let d2 = d.setDate(d.getDate() + nr);
      this.newtodo.duedate = d.toISOString().split('T')[0];
  }

  edit(item: Todo)
  {
    this.router.navigateByUrl('/intranet/todo/' + item.id, { skipLocationChange: true });
  }

  view(item: Todo)
  {
    this.todo = item;
  }

  change_status(item)
  {

    this.api.setTodo(item).subscribe((data:Response)=>{
      this.api.showSuccess("Sparad", "Datan sparades");
    }, error => this.api.showError(error));

  }


  close()
  {
    this.todo = new Todo;
  }

  save(t: Todo)
  {

    if (!t.id)
    {
      t.users.push(this.data.currentUser);
    }

    this.api.setTodo(t).subscribe(() => {

      this.todo = new Todo;
      this.updatetodo();

    })


  }

  drop(event: CdkDragDrop<string[]>, day) {




    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {

      let item: any = event.previousContainer.data[event.previousIndex];

      if (day == "today")
      {
        let d = new Date();
        item.duedate = new Date(d.getTime() - (d.getTimezoneOffset() * 60000 ))
        .toISOString()
        .split("T")[0];
      }
      else if (day == "tomorrow")
      {
        let d = new Date();
        let d2 = d.setDate(d.getDate() + 1);
        item.duedate = new Date(d.getTime() - (d.getTimezoneOffset() * 60000 ))
        .toISOString()
        .split("T")[0];
      }
      else if (day == "someday")
      {
        let d = new Date();
        let d2 = d.setDate(d.getDate() + 5);
        item.duedate = new Date(d.getTime() - (d.getTimezoneOffset() * 60000 ))
        .toISOString()
        .split("T")[0];
      }

      this.api.setTodo(item).subscribe((data:Response)=>{
        this.api.showSuccess("Sparad", "Datan sparades");
      }, error => this.api.showError(error));

      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);



    }
  }

}
