import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Lunch } from '../../../../../shared/models/lunch';
import { Response } from '../../../../../shared/models/response';
import { ApiService } from 'src/app/Services/api.service';
import { DataService } from 'src/app/Services/data.service';
import { week } from '../lunch-edit/lunch-edit.component';
import { environment } from 'src/environments/environment';
import { Media } from '../../../../../shared/models/media';
import { DropzoneComponent, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

@Component({
  selector: 'app-lunch-standard',
  templateUrl: './lunch-standard.component.html',
  styleUrls: ['./lunch-standard.component.scss']
})
export class LunchStandardComponent implements OnInit {

  heading = 'Standardlunchvecka';
  subheading = 'Här skapar du en ny standardlunchvecka';
  icon = 'pe-7s-up-arrow icon-gradient bg-malibu-beach';

  baseUrl:string = environment.apiUrl;

  public mediaSearchResult: Media[] = [];
  public s = "";

  public dropConfig: DropzoneConfigInterface = {
    clickable: true,
    maxFiles: 10,
    autoReset: null,
    errorReset: null,
    cancelReset: null,
    url: this.baseUrl + '/api/media/upload?customer_id=' + this.data.currentUser.customer.id
  };
  @ViewChild(DropzoneComponent) DropZone?: DropzoneComponent;

  public lunch: Lunch = new Lunch();

  public weeks: week[] = [];


  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {
  }


  ngOnInit() {


    this.api.getMedias(this.data.currentUser.customer.id).subscribe((data:Media[]) =>{
      this.mediaSearchResult = data;
    })


    this.api.getStandardLunch().subscribe((data) => {


      data.forEach(element => {

        if (element)
        {

          if (element.meta_key == "everyday") { this.lunch.everyday = element.meta_value; }
          if (element.meta_key == "day_1") { this.lunch.day_1 = element.meta_value; }
          if (element.meta_key == "day_2") { this.lunch.day_2 = element.meta_value; }
          if (element.meta_key == "day_3") { this.lunch.day_3 = element.meta_value; }
          if (element.meta_key == "day_4") { this.lunch.day_4 = element.meta_value; }
          if (element.meta_key == "day_5") { this.lunch.day_5 = element.meta_value; }
          if (element.meta_key == "day_6") { this.lunch.day_6 = element.meta_value; }
          if (element.meta_key == "day_7") { this.lunch.day_7 = element.meta_value; }
          if (element.meta_key == "automatic") { this.lunch.automatic = element.meta_value; }
          if (element.meta_key == "media_0") { this.lunch.media_0 = JSON.parse(element.meta_value); }
          if (element.meta_key == "media_1") { this.lunch.media_1 = JSON.parse(element.meta_value); }
          if (element.meta_key == "media_2") { this.lunch.media_2 = JSON.parse(element.meta_value); }
          if (element.meta_key == "media_3") { this.lunch.media_3 = JSON.parse(element.meta_value); }
          if (element.meta_key == "media_4") { this.lunch.media_4 = JSON.parse(element.meta_value); }
          if (element.meta_key == "media_5") { this.lunch.media_5 = JSON.parse(element.meta_value); }
          if (element.meta_key == "media_6") { this.lunch.media_6 = JSON.parse(element.meta_value); }
          if (element.meta_key == "media_7") { this.lunch.media_7 = JSON.parse(element.meta_value); }



        }

      });

    })


  }

  save()
  {
    this.api.setStandardLunch(this.lunch).subscribe((data:Response)=>{
        this.api.showSuccess("Sparad", "Standardlunchveckan sparades");
    }, error => this.api.showError(error));

  }

  img_search(){

    this.api.searchMedias(this.data.currentUser.customer.id, this.s).subscribe((data:Media[]) =>{
      this.mediaSearchResult = data;
    })

  }

  addimage(index, m: Media)
  {

    if (index == 0) { this.lunch.media_0 = m; }
    if (index == 1) { this.lunch.media_1 = m; }
    if (index == 2) { this.lunch.media_2 = m; }
    if (index == 3) { this.lunch.media_3 = m; }
    if (index == 4) { this.lunch.media_4 = m; }
    if (index == 5) { this.lunch.media_5 = m; }
    if (index == 6) { this.lunch.media_6 = m; }
    if (index == 7) { this.lunch.media_7 = m; }

  }

  removeImage(index)
  {

    if (index == 0) { this.lunch.media_0 = null; }
    if (index == 1) { this.lunch.media_1 = null; }
    if (index == 2) { this.lunch.media_2 = null; }
    if (index == 3) { this.lunch.media_3 = null; }
    if (index == 4) { this.lunch.media_4 = null; }
    if (index == 5) { this.lunch.media_5 = null; }
    if (index == 6) { this.lunch.media_6 = null; }
    if (index == 7) { this.lunch.media_7 = null; }

  }


  public onUploadInit(index, args: any): void {
    console.log('onUploadInit:', args);
  }

  public onUploadError(index, args: any): void {
    console.log('onUploadError:', args);
  }

  public onUploadSuccess(index, args: any): void {

    let id:number = +args[1];

    this.api.getMedia(id).subscribe((data: Media) => {

      if (index == 0) { this.lunch.media_0 = data; }
      if (index == 1) { this.lunch.media_1 = data; }
      if (index == 2) { this.lunch.media_2 = data; }
      if (index == 3) { this.lunch.media_3 = data; }
      if (index == 4) { this.lunch.media_4 = data; }
      if (index == 5) { this.lunch.media_5 = data; }
      if (index == 6) { this.lunch.media_6 = data; }
      if (index == 7) { this.lunch.media_7 = data; }

      this.DropZone.directiveRef.reset();
    })


  }



}
