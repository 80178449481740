import { Media } from './media';

export class Byline {

    public id: number|null = null;;
    public name: string|null = null;;
    public media: Media|null = null;;
    public content: string|null = null;;

}

