import { App } from './app';
import { Media } from './media';

export class AudioShow {

    public id: number;
    public title: String;
    public status: number;
    public type: string;
    public url: string;
    public body: String;
    public media: Media;
    public apps: App[] = [];


}

