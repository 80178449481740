import { Ad } from './ad';
import { AdTag } from './adtag';
import { menuObject } from './menuObject';
import { Place } from './place';

export class App {

    public id: number|null = null;
    public name: String|null = null;
    public shortName: String|null = null;
    public domain: String|null = null;
    public support_email: string|null = null;
    public notification_email: string|null = null;
    public news_email: string|null = null;
    
    public menu: String|null = null;
    public menu2: String|null = null;
    public booli_query: String|null = null;
    public station_str: string|null = null;
    public police_url: String|null = null;

    public pollen_rss: String|null = null;
    public death_url: String|null = null;
    public electric_area: String|null = null;

    public workads_url: String|null = null;
    public trafic_xml: String|null = null;
    public firebase_key: string|null = null;
    public workads_code: string|null = null;

    public keywords: string|null = null;

    public places: Place[]|null = null;
    public newsapps: App[]|null = null;
    public url_scheme: string|null = null;
    public itunes_link: string|null = null;
    public googleplay_link: string|null = null;
    public buildfolder: string|null = null;
    public appidentifier: string|null = null;

    public icon: string|null = null;
    public splash: string|null = null;
    public androidicon: string|null = null;
    public androidbackground: string|null = null;
    public headerimage: string|null = null;

    public news_queue: string|null = null;

    public event_basetools: string|null = null;

    public adTags: AdTag[]|null = null;

    public newsOptIn: boolean|null = null;
    public css: string|null = null;
    public menuObject2: menuObject[]|null = null;

    public share_base: string|null = null;
    public portal: string|null = null;
    public instagram_id: string|null = null;
    public facebook_id: string|null = null;
    public facebook_token: string|null = null;

    public timeSinceLastNews: number|null = null;
    public newsInQueue: Ad[]|null = [];
    public commingNews: Ad[]|null = [];
    public draftNews: Ad[]|null = [];

    public lastmonth_ad_views: number|null = null;
    public lastmonth_ad_clicks: number|null = null;
    public lastmonth_news_views: number|null = null;
    public lastmonth_news_clicks: number|null = null;
    public lastmonth_news_total: number|null = null;
    public lastmonth_ads_total: number|null = null;
    public lastmonth_cpm: number|null = null;
    public lastmonth_cpm_forening: number|null = null;
    

}

