export class Feedback {

    public app_id: number;
    public created_at: string;
    public device_id: number;
    public id: number;
    public metas: {
        meta_key: string,
        meta_value: any
    }[];
    public processed: string;
    public type: string;
    public type_id: number
    public updated_at: string;

}

