import { App } from './app';
import { Customer } from './customer';
import { Byline } from './byline';
import { Device } from './device';

export class User {

    public id: number|null = null;
    public username: String|null = null;
    public email: string|null = null;
    public password: String|null = null;
    public role: string|null = null;
    public name: String|null = null;
    public token: String|null = null;

    public avatar: string|null = null;

    public apps: App[] = [];
    public selectedApps: App[] = [];
    public selectedCustomer_ids: number[] = [];
    public selectedStatus: boolean = false;
    public selectedEmployees: User[] = [];
    public customers: Customer[] = [];
    public available_customers: Customer[] = [];

    public customer: Customer|null = null; // Current Customer;
    public customer_id: number|null = null; // Current Customer;

    public isJournalist: number|null = null;
    public noPrefilledAdCity: number|null = null;
    public smallAdmin: number|null = null;
    
    public isEmployee: number|null = null;
    public isColumnist: number|null = null;

    public canSetCustomTemplates: number|null = null;

    public columnistText: string|null = null;
    public columnistApps: App[] = [];

    public default_byline: Byline|null = null;
    public default_app: App|null = null;
    public default_startword: string|null = null;

    public lastseen: string|null = null;

    public status: number|null = null;

    public active_plan: string|null = null;

    public devices: Device[] = [];
    public maxdevices: number|null = null;



}

