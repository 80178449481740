import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Ad } from '../../../../../shared/models/ad';
import { App } from '../../../../../shared/models/app';
import { Media } from '../../../../../shared/models/media';
import { DropzoneConfigInterface, DropzoneComponent } from 'ngx-dropzone-wrapper';
import { DataService } from 'src/app/Services/data.service';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Response } from '../../../../../shared/models/response';
import { Serie } from '../../../../../shared/models/serie';
import { Byline } from '../../../../../shared/models/byline';
import { Subscription } from "rxjs";
import { AdTag } from '../../../../../shared/models/adtag';
import { environment } from '../../../environments/environment';
import { Summary } from '../../../../../shared/models/summary';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-news-edit-summary',
  templateUrl: './news-edit-summary.component.html',
  styleUrls: ['./news-edit-summary.component.scss']
})
export class NewsEditSummaryComponent implements OnInit {
  baseUrl:string = environment.apiUrl;
  heading = 'Skapa nyhetssammanfattning';
  subheading = '';
  icon = 'pe-7s-note icon-gradient bg-arielle-smile';

  public ad: Ad = new Ad();
  public apps: App[] = [];
  public s: String;
  public mediaSearchResult: Media[] = [];
  public preview_str: String;
  public preview_bodystr: String;
  public series: Serie[];
  public bylines: Byline[];
  public m_description: String[];
  private img_subscription: Subscription;

  public aiButtonPick: boolean = true;
  public aiButtonSummary: boolean = true;
  public aiButtonImageSearch: boolean = true;
  

  public summaries: any[] = [];

  public ai_text_1: String;
  public ai_text_2: String;
  public ai_text_3: String;

  public ai_pre_text_1: String;
  public ai_pre_text_2: String;
  public ai_pre_text_3: String;

  public selectedApp: App;

  private gotSummaryCounts: boolean = false;

  public dropConfig: DropzoneConfigInterface = {
    clickable: true,
    maxFiles: 10,
    autoReset: null,
    errorReset: null,
    cancelReset: null,
    timeout: 120000,
    url: this.baseUrl + '/api/media/upload?customer_id=' + this.data.currentUser.customer.id
  };
  @ViewChild(DropzoneComponent) DropZone?: DropzoneComponent;
  @ViewChild('preview_card') public preview_card: ElementRef;
  @ViewChild('news_preview') public news_preview: ElementRef;
  @ViewChild('news_preview2') public news_preview2: ElementRef;

  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router, private toastr: ToastrService) {
  }

  ngOnInit() {
    this.loadNews();
  }

  async loadNews() {
    let apps = this.data.currentUser.customer.apps;

    if (!apps)
    {
      await this.api.getUser(this.data.currentUser.id).subscribe((data: any) => {

        this.data.currentUser.customer.apps = data.customer.apps;
        apps = this.data.currentUser.customer.apps;

      });
    }

    if (!this.gotSummaryCounts)
    {
      this.api.getSummaryCount().subscribe(datas => {

        this.gotSummaryCounts = true;
        let index = 0;

        for(let app of apps){

          for(let data of datas){

            if (apps[index].id == +data.app_id)
            {
              apps[index].name += " (" + data.count + ") (" + data.diff + " dagar sedan)"; 
              break;
            }

          };

          index++;

        }
        this.apps = apps.filter(a => a.name.includes("("));

        let app_id = +this.route.snapshot.paramMap.get("app_id");
        if (app_id > 0)
        {
          this.selectedApp = this.apps.find(a => a.id == app_id);
          this.change();
        }

      });
    }

    this.api.getMedias(this.data.currentUser.customer.id, "news").subscribe((data:Media[]) => {
      this.mediaSearchResult = data;
    })


    let id = +this.route.snapshot.paramMap.get("id");

    if (id != 0) {
      this.heading = "Redigera nyhet"
      this.api.getAd(id).subscribe((data: Ad) => {
        this.ad = data;
        this.ad.timeupdate = new Date().toISOString();
        this.preview();
      })
    }
    else {

      this.resetAd();

    }
  }

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '5rem',
    minHeight: '2rem',
    placeholder: 'Skriv texten här...',
    translate: 'no'
  };


  resetAd()
  {
    this.ad = new Ad;
    this.ad.id = 0;
    this.ad.customer_id = this.data.currentUser.customer.id;
    let now = new Date();
    let stop = new Date(now.getTime() + 10000*24*60*60*1000);
    this.ad.timestart = now.toISOString();
    this.ad.timestop = stop.toISOString();
    this.ad.preword = '';
    this.ad.vinjett = "Nyheter";
    this.ad.isSummary = 1;
    this.ad.status = 0;
    this.ad.notify = 'yes';
    this.ad.isFree = '0';
    this.ad.notifyAll = '1';
    this.preview_str = "";
    this.preview_bodystr = "";
    this.preview();
    this.api.getByline(27).subscribe(data => {
      this.ad.byline = data;
    })
    this.ai_text_1 = null;
    this.ai_text_2 = null;
    this.ai_text_3 = null;
    this.ai_pre_text_1 = null;
    this.ai_pre_text_2 = null;
    this.ai_pre_text_3 = null;

  }


  save(clear = false) {
    this.ad.body = this.ad.body.split('&#10;').join(' ');
    this.ad.customer_id = this.data.currentUser.customer.id;
    let timestart_save = this.ad.timestart;
    let timestart = this.api.getDateStr(new Date(this.ad.timestart));
    this.ad.timestart = timestart;
    let timestop = this.api.getDateStr(new Date(this.ad.timestop));
    this.ad.timestop = timestop;

    this.ad.user_id = this.data.currentUser.id;

    this.api.setAd(this.ad).subscribe((data:Response)=> {

      if (this.ad.id == 0)
      {
        if (clear)
        {
          this.clearsummary();
        }
      }

      this.ad.id = data.id;
      this.ad.timestart = timestart_save;
      this.toastr.success("Nyheten sparades", "Sparad", { progressBar: true, closeButton: true });
      this.resetAd();


    }, error => this.api.showError(error));

    
    

  }

  clearsummary()
  {
    this.api.clearSummary(this.selectedApp.id).subscribe(() => {});
    this.summaries = [];
  }

  preview() {
    if (!this.ad.body)
    {
      this.ad.body = '';
    } 

    this.ad.body = this.ad.body.split('&#10;').join(' ');
    this.api.previewAd(this.ad).subscribe((data:any)=> {
      this.preview_str = data.feed;
      this.preview_bodystr = data.full;

      if (!document.getElementById("template_css_" + data.feed_template_id)) { 
        $("head").append('<style id="template_css_' + data.feed_template_id + '" type="text/css">' + data.feed_css + '</style>'); 
      }
      else {
        $("#template_css_" + data.feed_template_id).text(data.feed_css); 
      }

      if (!document.getElementById("template_css_" + data.full_template_id)) { 
        $("head").append('<style id="template_css_' + data.full_template_id + '" type="text/css">' + data.full_css + '</style>'); 
      }
      else { 
        $("#template_css_" + data.full_template_id).text(data.full_css); 
      }

      let width = this.preview_card.nativeElement.offsetWidth - 40;
      let zoom = width / 1000;
      this.news_preview.nativeElement.style.zoom = zoom ;
      this.news_preview2.nativeElement.style.zoom = zoom ;

      setTimeout( function() {
        var timeElements = document.getElementsByClassName('ad_time');
        for(var i = 0; i < timeElements.length; i++) {
          let time = new Date(timeElements[i].getAttribute('datetime'));
          //@ts-ignore
          timeElements[i].innerText = TimeAgo.inWords(time.getTime());
        }

      //@ts-ignore
      var slider = tns({ container: '.bd_slider',
      items: 1,
      autoHeight: false,
      controls: false,
      nav: false,
      speed: 400,
      autoplay: true,
      mouseDrag: true });
      },1);
    }, error => this.api.showError(error));
  }

  img_search(){
    if (this.img_subscription && !this.img_subscription.closed) {
      this.img_subscription.unsubscribe();
    }
    this.img_subscription = this.api.searchMedias(this.data.currentUser.customer.id, this.s, "news").subscribe((data:Media[]) =>{
      this.mediaSearchResult = data;
    })
  }

  addimage(m: Media) {
    this.ad.medias.push(m);
    this.preview();
  }

  addimagetext(m: Media) {
    this.ad.body += "<img src='" + this.baseUrl + "/uploads/" + m.src + "' style='width:100%'/>"
  }

  removeImage(index) {
    this.ad.medias.splice(index, 1);
    this.preview();
  }

  check_media(m: Media) {
    return !this.ad.medias.find(x => x["id"] === m.id);
  }

  public onUploadInit(args: any): void {
    console.log('onUploadInit:', args);
  }

  public onUploadError(args: any): void {
    console.log('onUploadError:', args);
  }

  public onUploadSuccess(args: any): void {
    let id:number = +args[1];

    this.api.getMedia(id).subscribe((data: Media) => {
      this.ad.medias.push(data);
      this.DropZone.directiveRef.reset();
    })
  }

  change() {
    
    let headings = ['Nyheter från ' + this.selectedApp.shortName,
    this.selectedApp.shortName + ' i korthet',
    'Nyheter i korthet',
    'Dagens nyhetssammanfattning',
    'Hänt i ' + this.selectedApp.shortName,
    'Hänt idag',
    'Nyheter idag',
    'Dagens händelser',
    'Senaste nyheterna för ' + this.selectedApp.shortName]

    this.ad.apps = [];
    this.ad.apps.push(this.selectedApp);
    this.ad.title = headings[Math.floor(Math.random() * headings.length)];


    this.api.getSummary(this.selectedApp.id).subscribe(data => {

      let newSummaries:any[] = [];
      
      for (let summary of data) {
        
        summary.show = true;

        if (newSummaries.filter(x => x.title == summary.title && x.updated == summary.updated).length > 0) {
          summary.show = false;
        } 
        if (summary.title.includes("Medan du sov"))
        {
          summary.show = false;
        }
        if(summary.title.includes("Lidköpingsnytt"))
        {
          summary.show = false;
        }

        newSummaries.push(summary);

      }


      this.summaries = newSummaries;
      console.log(data);

    })

  }

  public ai_summary()
  {
    let question = "Skriv en sammanfattning av följande text:\n\n";

    question = question + this.ad.body;

    this.aiButtonSummary = false;

    this.api.getAiAnswer(question).subscribe(data => {

      if (data=="")
      {
        this.aiButtonPick = true;
        return;
      }

      this.ad.body = data;
      this.aiButtonSummary = true;

    })

  }

  public ai_summary_pre()
  {
    let question = "Skriv en sammanfattning av följande text:\n\n";
/*
    if (this.ai_pre_text_1)
    {
      this.api.getAiAnswer(question + this.ai_pre_text_1).subscribe(data => {

        if (data=="")
        {
          return;
        }

        this.ai_text_1 = data;
      })
    }

    if (this.ai_pre_text_2)
    {
        this.api.getAiAnswer(question + this.ai_pre_text_2).subscribe(data => {

          if (data=="")
          {
            return;
          }

          this.ai_text_2 = data;
      })
    }

    */
    if (this.ai_pre_text_3)
    {
        this.api.getAiAnswer(question + this.ai_pre_text_3).subscribe(data => {
        if (data=="")
        {
          return;
        }

        this.ai_text_3 = data;
      })  
    }




  }

  public ai_checkheadings() {

    let question = "Vilka fem rubriker är mest intressanta av följande?\n\n";

    this.aiButtonPick = false;

    for (let summary of this.summaries) {
      if (summary.show) {
        question = question + "* " + summary.title + "\n";
      }

    }

    this.api.getAiAnswer(question).subscribe(data => {

      if (data=="")
      {
        this.aiButtonPick = true;
        return;
      }

      this.aiButtonPick = true;

      this.summaries.forEach((summary, index) => {

        if (!data.includes(summary.title))
        {
          this.summaries[index].show = false;
        }

      })

    })

  }

  public ai_imageSearch()
  {
    let question = "Välj ut fem nyckelord från följande text:\n\n";

    question = question + this.ad.body;

    this.aiButtonImageSearch = false;
    this.ad.medias = []

    this.api.getAiAnswer(question).subscribe(data => {

      if (data == "")
      {
        this.aiButtonImageSearch = true;
        return;
      }

      data = data.replace("Nyckelord:", " ");
      data = data.replace("1.", " ");
      data = data.replace("2.", " ");
      data = data.replace("3.", " ");
      data = data.replace("4.", " ");
      data = data.replace("5.", " ");

      let keywords = data.split(",");
      
      this.s = "";

      if (keywords.length < 4)
      {
        keywords = data.split("-");
      }

      for(let keyword of keywords)
      {
        keyword = keyword.trim();

        if (keyword.length > 3)
        {
          this.s = this.s + keyword + " ";

          this.aiButtonImageSearch = false;
          this.api.searchMedias(this.data.currentUser.customer.id, keyword, "news").subscribe((data: Media[]) => {
              this.mediaSearchResult = data;

              this.aiButtonImageSearch = true;

              if (this.mediaSearchResult.length > 0) {
                this.addimage(this.mediaSearchResult[0]);
              }

            })
        }

      }

      this.aiButtonImageSearch = true;


    });


  }

  add_all() {
    for (let summary of this.summaries) {
      if (summary.show) {
        this.add(summary);
      }
    }

    this.ai_summary_pre();
  }

  add(summary: Summary)
  {

    if (!this.ad.summaries)
    {
      this.ad.summaries = [];
    }
    if (!this.ad.body)
    {
      this.ad.body = '';
    } 
    if (!summary.type)
    {
      summary.type = "TEXT";
    }

    this.ad.body = "Bland de senaste nyheterna: " + this.ad.body + summary.title + "<br />"; // + summary.body + "<br />";

    this.ai_pre_text_1 += summary.title + "\n";
    this.ai_pre_text_2 += summary.body + "\n";
    this.ai_pre_text_3 += summary.title + "\n" + summary.body + "\n";



    this.ad.summaries.push(summary);

    console.log(this.ad.summaries);

  }

  openScreen(screen: string, Queryurl: string) {
  
    let url = "http://localhost:8082/?action=open&screen=" + screen + "&url=" + Queryurl

    this.http.get(url).subscribe((data:any) => {});

  }

  remove_summary(index)
  {
    this.ad.summaries.splice(index, 1);
  }

  public delete() {
    if(confirm("Är du säker på att du vill ta bort den här nyheten?")) {
      this.api.delAd(this.ad.id).subscribe((data:any) => {
        this.router.navigateByUrl('/newses');
      });
    }
  }

}

