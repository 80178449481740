import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTablesResponse } from '../../../../../shared/models/datatabelsResponse';
import { Todo } from '../../../../../shared/models/todo';
import { User } from '../../../../../shared/models/user';
import { ApiService } from 'src/app/Services/api.service';
import { DataService } from 'src/app/Services/data.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-intranet-tasks',
  templateUrl: './intranet-tasks.component.html',
  styleUrls: ['./intranet-tasks.component.scss']
})
export class IntranetTasksComponent implements OnInit {
  baseUrl:string = environment.apiUrl;
  heading = 'Uppgifter';
  subheading = 'Alla uppgifter';
  icon = 'pe-7s-timer icon-gradient bg-arielle-smile';

  public todos: Todo[] = [];
  public employees: User[];
  public selectedEmployees: User[] = null;

  dtOptions: DataTables.Settings = {};

  constructor(private data: DataService, private http: HttpClient,
    private route: ActivatedRoute, private router: Router, private api: ApiService) {
      this.api.getEmployees().subscribe((data: User[]) => {
        this.employees = data;
      })

      this.selectedEmployees = this.data.currentUser.selectedEmployees;
  }

  ngOnInit(): void {
    const that = this;
    let userstr = "";

    if (this.selectedEmployees != null) {
      this.selectedEmployees.forEach(x => {
        userstr += x.id + ","
      })

      userstr += "-1"
    }
    else {
      this.selectedEmployees = [];
      this.selectedEmployees.push(this.data.currentUser);
      userstr = this.data.currentUser.id.toString();
    }

    this.dtOptions = {
      order: [[ 1, "desc" ]],
      pagingType: 'full_numbers',
      pageLength: 25,
      serverSide: true,
      processing: true,
      language: {
        "emptyTable":     "Det finns ingen data i tabellen",
        "info":           "Visar rad _START_ till _END_ av _TOTAL_ rader",
        "infoEmpty":      "Visar post 0 till 0 av 0 poster",
        "infoFiltered":   "(av totalt _MAX_ poster)",
        "infoPostFix":    "",
        "thousands":      ",",
        "lengthMenu":     "Visa _MENU_ rader per sida",
        "loadingRecords": "Laddar...",
        "processing":     "Laddar...",
        "search":         "Sök:",
        "zeroRecords":    "Inget matchar sökningen",
        "paginate": {
            "first":      "Första",
            "last":       "Sista",
            "next":       "Nästa",
            "previous":   "Föregående"
        },
        "aria": {
            "sortAscending":  ": activate to sort column ascending",
            "sortDescending": ": activate to sort column descending"
        }
    },
      ajax: (dataTablesParameters: any, callback) => {
        that.http
          .post<DataTablesResponse>(
            this.baseUrl + '/api/todos?userstr=' + userstr,
            dataTablesParameters, {}
          ).subscribe(resp => {

            that.todos = resp.data;

            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
      columns: [{ data: 'title' }, { data: 'duedate' }, { data: 'status' }]
    };
  }

  ngOnDestroy(): void {
  }

  change() {
    this.data.currentUser.selectedEmployees = this.selectedEmployees;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(
      x => {
        this.router.navigateByUrl('/intranet/todos', { skipLocationChange: true });
      }
    )
  }
}
