import { Injectable } from '@angular/core';
import { User } from '../../../../shared/models/user';
import { webSocket } from "rxjs/webSocket";
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { FacebookService, InitParams } from 'ngx-facebook';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public currentUser: User = new User();
  public timeline_start: Date;
  public timeline_stop: Date;
  public screen: string;

  constructor(private router: Router, public fb: FacebookService) { 
 
      const initParams: InitParams = {
        appId: '1646608665638879',
        xfbml: true,
        version: 'v15.0'
      };
   
      this.fb.init(initParams);
   

    const subject = webSocket('ws://localhost:8083');

    subject.subscribe(
       (msg:any) => {
        


        if (msg.screen == this.screen)
        {
          let changed = false;

          if (msg.appId && msg.appId != this.currentUser.default_app.id)
          {
            let appId = msg.appId;
            this.currentUser.default_app = this.currentUser.apps.find(a => a.id == appId);
            changed = true;
          }

          if (msg.customerId && msg.customerId != this.currentUser.customer.id)
          {
            let customerId = msg.customerId;
            this.currentUser.customer = this.currentUser.available_customers.find(c => c.id == customerId);
            changed = true;
          }
        
          if (msg.url)
          {
            let url = msg.url;

            if (changed)
            {
              this.router.navigateByUrl("/adminScreen").then(() => {
                this.router.navigate([url]);
              });
            }
            else
            {
              this.router.navigateByUrl(url);
            }

          }


        }

        console.log('message received: ');
        console.log(msg);

      }, 
       err => {

        console.log(err)
       // alert(JSON.stringify(err));

       }, // Called if at any point WebSocket API signals some kind of error.
       () => {
      //  alert("disconneced");
       } // Called when connection is closed (for whatever reason).
     );

  }

  public fixDate(dt: any)
  {

  if (dt)
  {
   if (typeof dt.getMonth === 'function')
   {
      let retval = dt.getFullYear() + "-"

      if (dt.getMonth() < 9)
      {
        retval += "0";
      }
      retval += (dt.getMonth()+1) + "-";

      if (dt.getDate() < 10)
      {
        retval += "0";
      }
      retval += dt.getDate() + "T";

      if (dt.getHours() < 10)
      {
        retval += "0";
      }
        retval += dt.getHours() + ":";

      if (dt.getMinutes() < 10)
      {
          retval += "0";
      }
      retval += dt.getMinutes() + ":00.000";

    return retval;

   }
   else
   {
     return dt;
   }
  }
  else
  {
    return dt;
  }

  }
}
