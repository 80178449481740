import { App } from './app';
import { Section } from './section';

export class SectionPage {

    public id: number;
    public name: string;
    public app: App;
    public sort: number;
    public sections: Section[];

}

