import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Customer } from '../../../../../shared/models/customer';
import { Response } from '../../../../../shared/models/response';
import { Todo } from '../../../../../shared/models/todo';
import { User } from '../../../../../shared/models/user';
import { ApiService } from 'src/app/Services/api.service';
import { DataService } from 'src/app/Services/data.service';

@Component({
  selector: 'app-intranet-task-edit',
  templateUrl: './intranet-task-edit.component.html',
  styleUrls: ['./intranet-task-edit.component.scss']
})
export class IntranetTaskEditComponent implements OnInit {

  heading = 'Skapa uppgift';
  subheading = 'Skapa en ny uppgift';
  icon = 'pe-7s-up-arrow icon-gradient bg-malibu-beach';

  public todo: Todo = new Todo();
  public employees: User[] = [];
  public customers: Customer[] = [];

  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {
    let id = +this.route.snapshot.paramMap.get("id");

    if (id != 0)
    {
      this.api.getTodo(id).subscribe((data: Todo) => {
        this.todo = data;

        this.todo.duedate = new Date(this.todo.duedate).toISOString();

      })
    }
    else
    {
      this.todo.id = 0;
      this.todo.users.push(this.data.currentUser);
      this.todo.status = 0;
    }

    this.api.getCustomers().subscribe((data: Customer[]) => {
      this.customers = data;
    })

  }





  ngOnInit() {



  }

  save()
  {
    let time = this.todo.duedate;
    let timestart = this.api.getDateStr(new Date(this.todo.duedate));
    this.todo.duedate = timestart;

    this.api.setTodo(this.todo).subscribe((data:Response)=>{
      this.todo.id = data.id;
      this.todo.duedate = time;
      this.api.showSuccess("Sparad", "Annonsen sparades");
    }, error => this.api.showError(error));

  }

  public delete()
  {
    if(confirm("Är du säker på att du vill ta bort den här uppgiften?")) {

      this.api.delTodo(this.todo.id).subscribe((data:any) => {
        this.router.navigateByUrl('/intranet/todos');
      });
    }
  }




}

