import {Component, OnInit} from '@angular/core';
import {ThemeOptions} from '../../../../../theme-options';
import { DataService } from 'src/app/Services/data.service';
import { ApiService } from 'src/app/Services/api.service';
import { Response } from '../../../../../../../../shared/models/response';
import { Router } from '@angular/router';
import { environment } from '../../../../../../environments/environment';
import { LoginOptions, LoginResponse, UIResponse } from 'ngx-facebook';
import { Customer } from '../../../../../../../../shared/models/customer';
import { set } from 'date-fns';

@Component({
  selector: 'app-user-box',
  templateUrl: './user-box.component.html',
})
export class UserBoxComponent implements OnInit {
  baseUrl:string = environment.apiUrl;

  public fb_conneced: boolean = false;
  public pages: any[] = [];
  public incomming_newstips: number = 0;
  public incomming_readernews: number = 0;
  public incomming_debate: number = 0;
  public incomming_events: number = 0;
  public incomming_privateads: number = 0;
  public incomming_photos: number = 0;



  toggleDrawer() {
    this.globals.toggleDrawer = !this.globals.toggleDrawer;
  }

  constructor(public globals: ThemeOptions, public data: DataService,
    private api: ApiService, private router: Router) {
  }

  ngOnInit() {

    this.data.fb.getLoginStatus().then((res: LoginResponse) => {

      if (res.status == "connected")
      {
        this.data.fb.api('/me/accounts').then((res: UIResponse) => {

          this.fb_conneced = true;

        })
      }

    });

    this.getStatuses();


    setInterval(() => {
      this.getStatuses();
    }, 120*1000);

  }

  public getStatuses(){
    this.api.getIncomingStatus().subscribe((data: any) => {

      this.incomming_newstips = data.newstips;
      this.incomming_readernews = data.readernews;
      this.incomming_debate = data.debate;
      this.incomming_events = data.events;
      this.incomming_privateads = data.privateads;
      this.incomming_photos = data.photos;
      
    });
  }

  public open(type: string)
  {

    if (type == "newstips")
    {
      
      this.api.getCustomer(-23).subscribe((data: Customer) => {
        this.data.currentUser.customer = data;
        this.api.setUser(this.data.currentUser).subscribe((data: Response) => {
          sessionStorage.setItem("url", "/ads");
          this.router.navigateByUrl('/login');
        })
      });

    }

    if (type == "readernews")
    {
      
      this.api.getCustomer(-22).subscribe((data: Customer) => {
        this.data.currentUser.customer = data;
        this.api.setUser(this.data.currentUser).subscribe((data: Response) => {
          sessionStorage.setItem("url", "/ads");
          this.router.navigateByUrl('/login');
        })
      });

    }

    if (type == "debate")
    {
      
      this.api.getCustomer(-15).subscribe((data: Customer) => {
        this.data.currentUser.customer = data;
        this.api.setUser(this.data.currentUser).subscribe((data: Response) => {
          sessionStorage.setItem("url", "/ads");
          this.router.navigateByUrl('/login');
        })
      });

    }

    if (type == "privateads")
    {
      
      this.api.getCustomer(-24).subscribe((data: Customer) => {
        this.data.currentUser.customer = data;
        this.api.setUser(this.data.currentUser).subscribe((data: Response) => {
          sessionStorage.setItem("url", "/ads");
          this.router.navigateByUrl('/login');
        })
      });

    }


    if (type == "photos")
    {
      this.api.getCustomer(-19).subscribe((data: Customer) => {
        this.data.currentUser.customer = data;
        this.api.setUser(this.data.currentUser).subscribe((data: Response) => {
          sessionStorage.setItem("url", "/ads");
          this.router.navigateByUrl('/login');
        })
      });

    }

    if (type == "events")
    {
      this.api.getCustomer(-3).subscribe((data: Customer) => {
        this.data.currentUser.customer = data;
        this.api.setUser(this.data.currentUser).subscribe((data: Response) => {
          sessionStorage.setItem("url", "/allevents");
          this.router.navigateByUrl('/login');
        })
      });

    }

  }

  onChangeCustomer(event)
  {
    this.api.setUser(this.data.currentUser).subscribe((data: Response) => {
      sessionStorage.setItem("url", "");
      this.router.navigateByUrl('/login');
    })
  }

  logout() {
    localStorage.setItem("id", null);
    sessionStorage.setItem("id", null);
    this.data.currentUser = null;

    this.router.navigateByUrl('/login');
  }

  logout_fb() {

    this.data.fb.logout().then((res: LoginResponse) => {
      this.fb_conneced = false;
      console.log(res);
      
    });

  }

  login_fb() {

    let options: LoginOptions = {
      scope: 'pages_show_list,pages_manage_posts,pages_read_engagement,ads_management,business_management,instagram_basic,instagram_content_publish'
    }


    this.data.fb.login(options).then((res: LoginResponse) => {
      
      console.log(res);

      if (res.status == "connected")
      {

        this.fb_conneced = true;

        this.data.fb.api('/me/accounts?limit=500&fields=instagram_business_account{id,name,username,profile_picture_url},id,name,access_token,username,profile_picture_url').then((res: any) => {

          console.log(res);

          for(let i = 0; i < res.data.length; i++)
          {

            res.data[i].displayName = res.data[i].name;

            if (res.data[i].instagram_business_account)
            {
              res.data[i].displayName += " (Facebook + Instagram)";
            }
            else
            {
              res.data[i].displayName += " (Facebook)";
            }
          }

          this.pages = res.data;


        })
      }

    })
  }

  disconnect()
  {
    this.api.setFacebookID(this.data.currentUser.customer.id, null, null, null).subscribe((data: Response) => {
        
        this.api.showSuccess("Sparad!", "Facebooksidan är frånkopplad från ditt konto.");
        this.data.currentUser.customer.facebook_id = null;
  
      });
  }

  onChangeFacebook(event)
  {


    let page = this.pages.find(x => x.id == this.data.currentUser.customer.facebook_id);
    let instagram_id = null;
    if (page.instagram_business_account)
    {
      instagram_id = page.instagram_business_account.id;
    }

    this.api.setFacebookID(this.data.currentUser.customer.id, null, this.data.currentUser.customer.facebook_id, instagram_id).subscribe((data: Response) => {

      this.api.showSuccess("Sparad!", "Facebooksidan är nu kopplad till ditt konto.");

      let page = this.pages.find(x => x.id == this.data.currentUser.customer.facebook_id);

      this.data.fb.api('/oauth/access_token?grant_type=fb_exchange_token&client_id=1646608665638879&client_secret=33fe57a388643f82424957072ce0ef00&fb_exchange_token=' + page.access_token, "get").then((res: any) => {

        let access_token = res.access_token;

        this.data.fb.api('/oauth/access_token?grant_type=fb_attenuate_token&client_id=1646608665638879&fb_exchange_token=' + access_token, "get").then((res: any) => {


          this.data.fb.api('/debug_token?input_token=' + res.access_token + "&access_token=" + access_token, "get").then((res: any) => {

            console.log(res);

            let expired = res.data.data_access_expires_at;

            this.api.setFacebookToken("page", page.id, expired, access_token).subscribe((data: Response) => {
                  
                this.api.showSuccess("Sparad!", "Facebooksidans token är nu uppdaterat.");

            });
        })
      })
    });

  });

  }


}
