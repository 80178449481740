import { Component, OnInit } from '@angular/core';
import { Contract } from '../../../../../shared/models/contract';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { DataService } from 'src/app/Services/data.service';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Response } from '../../../../../shared/models/response';
import { Customer } from '../../../../../shared/models/customer';
import { App } from '../../../../../shared/models/app';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-contract-edit',
  templateUrl: './contract-edit.component.html',
  styleUrls: ['./contract-edit.component.scss']
})
export class ContractEditComponent implements OnInit {
  baseUrl:string = environment.apiUrl;
  heading = 'Redigera profilsidan';
  subheading = 'Här redigerar du din profilsida';
  icon = 'pe-7s-note icon-gradient bg-arielle-smile';

  public contract: Contract = new Contract();
  public apps: App[] = [];

  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {
      this.api.getApps().subscribe((data: App[]) => {
        this.apps = data;
      })

      let id = +this.route.snapshot.paramMap.get("id");
      this.loadContract(id);
  }

  ngOnInit() {
    this.route.params.subscribe(routeParams => {
      this.loadContract(routeParams.id);
    });
  }

  private loadContract(id: number) {
    this.contract.customer = new Customer;
    this.contract.customer.id = this.data.currentUser.customer.id;

    if (id != 0)
    {
      this.heading = 'Redigera avtal';
      this.subheading = 'Här redigerar du ett avtal';

      this.api.getContract(id).subscribe((data: Contract) => {
        this.contract = data;
      });
    }
    else
    {
      this.contract.id = 0;
    }
  }

  save() {
    this.api.setContract(this.contract).subscribe((data:Response) => {
      this.contract.id = data.id;

      this.api.showSuccess("Sparad", "Avtalet sparades");

    }, error => this.api.showError(error));
  }

  onUploadChange(evt: any) {
    const file = evt.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  handleReaderLoaded(e) {
    let img_str = btoa(e.target.result);
    this.api.setContractImg(this.contract.id, img_str).subscribe((data: Response) => {
      this.api.showSuccess("Uppladdad", "Avtalet sparades");

      this.loadContract(data.id);
    }, error => this.api.showError(error))
  }
}
