import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { DataService } from 'src/app/Services/data.service';
import { App } from '../../../../../shared/models/app';

@Component({
  selector: 'app-admin-add-familypage',
  templateUrl: './admin-add-familypage.component.html',
  styleUrls: ['./admin-add-familypage.component.scss']
})
export class AdminAddFamilypageComponent implements OnInit {

  heading = 'Lägg till sida från familjesidan';
  subheading = 'Här lägger du manuellt till nya dödsannonser';
  icon = 'pe-7s-phone icon-gradient bg-malibu-beach';

  public apps: App[];
  public selectedApp: App;

  public url = '';

  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute) {

    this.apps = this.data.currentUser.apps;

  }

  ngOnInit() {
  }

 

  save()
  {

    this.api.addFamilyPageUrl(this.url,this.selectedApp.id).subscribe(res => {
      this.api.showSuccess("Sparat", "Sidan har lagts till")
    }, err => {
      this.api.showError(err);
    });

  }

}