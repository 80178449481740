import { Customer } from './customer';
import { User } from './user';

export class Todo {

    public id: number;
    public title: string;
    public type: string;
    public status: number;
    public users: User[] = [];
    public customer: Customer;
    public duedate: string;

}

