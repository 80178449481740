import { Media } from "./media";

export class Lunch {

    public id: number;
    public datum: String;
    public day_1: String;
    public day_2: String;
    public day_3: String;
    public day_4: String;
    public day_5: String;
    public day_6: String;
    public day_7: String;
    public everyday: String;
    public automatic: number;

    public media_0: Media;
    public media_1: Media;
    public media_2: Media;
    public media_3: Media;
    public media_4: Media;
    public media_5: Media;
    public media_6: Media;
    public media_7: Media;


}

