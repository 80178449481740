
export class AdLog {

    public id: number|null = null;
    public ad_id: number|null = null;
    public type: string|null = null;
    public value: string|null = null;
    public ip: string|null = null;
    public name: string|null = null;
    public created_at: string|null = null;

}

