import { Tag } from './tag';

export class Media {

    public id: number|null = null;
    public src: string|null = null;
    public base64src: any = null;
    public src_thmb: string|null = null;
    public type: string|null = null;
    public cat: number|null = null;
    public tags: Tag[] = [];
    public foto: String|null = null;
    public description: String|null = null;
    public name: string|null = null;
    public path: string|null = null;
    public video_url: string|null = null;

}

