import { Emailtemplate } from './../../../../../shared/models/emailtemplate';
import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/Services/data.service';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Response } from '../../../../../shared/models/response';
import { App } from '../../../../../shared/models/app';

@Component({
  selector: 'app-admin-email-template-edit',
  templateUrl: './admin-email-template-edit.component.html',
  styleUrls: ['./admin-email-template-edit.component.scss']
})
export class AdminEmailTemplateEditComponent implements OnInit {

  heading = 'Skapa införandemall';
  subheading = 'Här skapar du nya införandemallar';
  icon = 'pe-7s-up-arrow icon-gradient bg-malibu-beach';
  public apps: App[];

  public template: Emailtemplate = new Emailtemplate();

  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router,) {
    let id = +this.route.snapshot.paramMap.get("id");

    if (id != 0)
    {

      this.heading = 'Redigera mallen';
      this.subheading = 'Här redigerar du en mall';

      this.api.getEmailTemplate(id).subscribe((data: Emailtemplate) => {
        this.template = data;

      })
    }
    else
    {
      this.template.id = 0;
    }

  }

  ngOnInit() {
    this.apps = this.data.currentUser.customer.apps;
  }

  save()
  {
    this.api.setEmailTemplate(this.template).subscribe((data:Response)=>{
        this.template.id = data.id;
        this.api.showSuccess("Sparad", "Mallen sparades");
    }, error => this.api.showError(error));

  }

  public delete()
  {
    if(confirm("Är du säker på att du vill ta bort den här mallen?")) {

      this.api.delEmailTemplate(this.template.id).subscribe((data:any) => {
        this.router.navigateByUrl('/admin/allemailtemplates');
      });
    }
  }
}
