import { Component, OnInit } from '@angular/core';
import { SectionPage } from '../../../../../shared/models/sectionPage';
import { App } from '../../../../../shared/models/app';
import { DataService } from 'src/app/Services/data.service';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Response } from '../../../../../shared/models/response';
import { Section } from '../../../../../shared/models/section';

@Component({
  selector: 'app-admin-sections-page-edit',
  templateUrl: './admin-sections-page-edit.component.html',
  styleUrls: ['./admin-sections-page-edit.component.scss']
})
export class AdminSectionsPageEditComponent implements OnInit {

  heading = 'Skapa sektionssida';
  subheading = 'Här skapar du en ny sektionssida';
  icon = 'pe-7s-server icon-gradient bg-malibu-beach';

  public sectionpage: SectionPage = new SectionPage();
  public sections: Section[];
  public apps: App[] = [];

  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {
    let id = +this.route.snapshot.paramMap.get("id");

    this.api.getApps().subscribe((data: App[]) => {
      this.apps = data;
    })

    this.api.getSections(this.data.currentUser.customer.id).subscribe((data: Section[]) => {
      this.sections = data;
    })

    if (id != 0)
    {

      this.heading = 'Redigera sektionssida';
      this.subheading = 'Här redigerar du en sektionssida.';

      this.api.getSectionPage(id).subscribe((data: SectionPage) => {
        this.sectionpage = data;
      })
    }
    else
    {
      this.sectionpage.id = 0;
    }

  }

  ngOnInit() {
    this.apps = this.data.currentUser.customer.apps;
  }



  save()
  {
    this.api.setSectionPage(this.sectionpage).subscribe((data:Response)=>{
      this.api.showSuccess("Sparad", "Sektionssidan sparades");
      if (this.sectionpage.id == 0)
      {
        this.router.navigateByUrl('/allsectionpages');
      }
    }, error => this.api.showError(error));
  }

}
