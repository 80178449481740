import { App } from './app';
import { Media } from './media';
import { VideoShowEpisode } from './videoShowEpisode';

export class VideoShow {

    public id: number;
    public title: String;
    public status: number;
    public body: String;
    public media: Media;
    public apps: App[] = [];
    public episodes: VideoShowEpisode[];


}

