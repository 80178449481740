import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DropzoneComponent, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { App } from '../../../../../shared/models/app';
import { AudioShow } from '../../../../../shared/models/audioShow';
import { Media } from '../../../../../shared/models/media';
import { Response } from '../../../../../shared/models/response';
import { ApiService } from 'src/app/Services/api.service';
import { DataService } from 'src/app/Services/data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-audio-show-edit',
  templateUrl: './admin-audio-show-edit.component.html',
  styleUrls: ['./admin-audio-show-edit.component.scss']
})
export class AdminAudioShowEditComponent implements OnInit {

  baseUrl:string = environment.apiUrl;
  heading = 'Skapa program';
  subheading = 'Här skapar du ett nytt program';
  icon = 'pe-7s-server icon-gradient bg-malibu-beach';

  public audioshow: AudioShow = new AudioShow();
  public apps: App[] = [];


  public mediaSearchResult: Media[] = [];
  public s: String;

  public dropConfig: DropzoneConfigInterface = {
    clickable: true,
    maxFiles: 1,
    autoReset: null,
    errorReset: null,
    cancelReset: null,
    url: this.baseUrl + '/api/media/upload?profile=false&customer_id=-17'
  };
  @ViewChild(DropzoneComponent) DropZone?: DropzoneComponent;

  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {
    let id = +this.route.snapshot.paramMap.get("id");

    if (id != 0)
    {

      this.heading = 'Redigera ett program';
      this.subheading = 'Här redigerar du ett program';

      this.api.getAudioShow(id).subscribe((data: AudioShow) => {

        this.audioshow = data;

      });
    }
    else
    {
      this.audioshow.id = 0;
    }

  }

  ngOnInit() {
    this.apps = this.data.currentUser.apps;

    this.api.getMedias(-17).subscribe((data:Media[]) =>{
      this.mediaSearchResult = data;
    })
  }


  save()
  {

    this.api.setAudioShow(this.audioshow).subscribe((data:Response)=>{
      this.audioshow.id = data.id;
      this.api.showSuccess("Sparad", "Musikobjektet sparades");
    }, error => this.api.showError(error));

  }

  img_search(){

    this.api.searchMedias(-17, this.s).subscribe((data:Media[]) =>{
      this.mediaSearchResult = data;
    })

  }

  addimage(m: Media)
  {
    this.audioshow.media = m;
  }

  removeImage()
  {
    this.audioshow.media = null;
  }

  public onUploadInit(args: any): void {
    console.log('onUploadInit:', args);
  }

  public onUploadError(args: any): void {
    console.log('onUploadError:', args);
  }

  public onUploadSuccess(args: any): void {

    let id:number = +args[1];

    this.api.getMedia(id).subscribe((data: Media) => {
      this.audioshow.media = data;
      this.DropZone.directiveRef.reset();
    })
  }


  public delete()
  {
    if(confirm("Är du säker på att du vill ta bort det här programmet?")) {

      this.api.delAudioShow(this.audioshow.id).subscribe((data:any) => {
        this.router.navigateByUrl('/admin/allaudioshows');
      });
    }
  }


}
