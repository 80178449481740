import { Customer } from './customer';
import { App } from './app';
import { Template } from './template';

export class Rss {

    public id: number|null = null;
    public name: String|null = null;
    public intervall: number|null = null;
    public last_rum: String|null = null;
    public customer: Customer|null = null;
    public app: App|null = null;
    public template_full: Template|null = null;
    public template_feed: Template|null = null;
    public keywords: String|null = null;
    public type: String|null = null;
    public status: number|null = null;
    public ad_status: number|null = null;
    public ad_max: number|null = null;
    public url: String|null = null;

    public summary_apps: App[] = [];
    public summary_title: String|null = null;
    public summary_type: String|null = null;
    public summary_paywall: number|null = null;

}

