import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { DataService } from 'src/app/Services/data.service';
import { Customer } from '../../../../../shared/models/customer';

@Component({
  selector: 'app-admin-screen',
  templateUrl: './admin-screen.component.html',
  styleUrls: ['./admin-screen.component.scss']
})
export class AdminScreenComponent implements OnInit {

  constructor(private route: ActivatedRoute, private api: ApiService,
    public data: DataService, private router: Router) { }

  async ngOnInit(): Promise<void> {

    await this.route.queryParams.subscribe(async params => {
    

      console.log(params);

      if (params['customerId'])
      {
        let customerId = params['customerId'];
        this.data.currentUser.customer = this.data.currentUser.available_customers.find(c => c.id == customerId);
      }

      if (params['appId'])
      {
        let appId = params['appId'];
        this.data.currentUser.default_app = this.data.currentUser.apps.find(a => a.id == appId);
      }

      if (params['screen'])
      {
        this.data.screen = params['screen'];
      }

      if (params['customerId'] || params['appId'])
      {
       await this.api.setUser(this.data.currentUser).subscribe((data: any) => {});
      }

      if (params['url'])
      {
        let url = params['url'];
      //  this.router.navigateByUrl(url);
      }


    });


  }

}
