export class menuObject { 
    public id: number|null = null;
    public type: string|null = null;
    public icon: String|null = null;
    public icon_img: String|null = null;
    public title: string|null = null;
    public picture: string|null = null;
    public link: string|null = null;
    public style: string|null = null;
    public textstyle: string|null = null;
  }