import { Customer } from './customer';
import { App } from './app';
import { EventCategory } from './eventcategory';
import { Media } from './media';
import { EventTime } from './eventtime';

export class Event {

    public id: number;
    public starttime: string;
    public endtime: string;
    public showend: number;
    public wholeday: number;
    public place: string;
    public title: string;
    public txt: string;
    public url: string;
    public customer: Customer;
    public media: Media;
    public app: App;
    public apps: App[];
    public times: EventTime[];
    public categories: EventCategory[];

    public from_name: string;
    public from_email: string;
    public from_phone: string;

    public audioShow: any;
}

