import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DropzoneComponent, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { App } from '../../../../../shared/models/app';
import { Customer } from '../../../../../shared/models/customer';
import { Event } from '../../../../../shared/models/event';
import { EventCategory } from '../../../../../shared/models/eventcategory';
import { LiveEvent } from '../../../../../shared/models/liveevent';
import { LiveEventEvent } from '../../../../../shared/models/liveevent_event';
import { Media } from '../../../../../shared/models/media';
import { Response } from '../../../../../shared/models/response';
import { ApiService } from 'src/app/Services/api.service';
import { DataService } from 'src/app/Services/data.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-live-event',
  templateUrl: './live-event.component.html',
  styleUrls: ['./live-event.component.scss']
})
export class LiveEventComponent implements OnInit {

  baseUrl:string = environment.apiUrl;
  heading = 'Skapa liveevent';
  subheading = 'Här skapar du ett nytt event';
  icon = 'pe-7s-server icon-gradient bg-malibu-beach';

  public liveevent: LiveEvent = new LiveEvent();
  public eventevent: LiveEventEvent = new LiveEventEvent();
  public apps: App[] = [];
  public customers: Customer[] = [];

  constructor(public data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {
    let id = +this.route.snapshot.paramMap.get("id");

    if (id != 0) {

      this.heading = 'Redigera Event';
      this.subheading = 'Här redigerar du ett event';

      this.api.getLiveEvent(id).subscribe((data: LiveEvent) => {
        this.liveevent = data;
      });
    }
    else {

      this.liveevent.id = 0;
      if (this.data.currentUser.customer.apps.length == 1) {
        this.liveevent.app = this.data.currentUser.customer.apps[0];
      }
    }

  }

  ngOnInit() {
    this.apps = this.data.currentUser.apps;
  }


  save() {
    this.liveevent.starttime = this.data.fixDate(this.liveevent.starttime);
    this.liveevent.customer = this.data.currentUser.customer;

    this.api.setLiveEvent(this.liveevent).subscribe((data:Response)=>{
      this.liveevent.id = data.id;
      this.api.showSuccess("Sparad", "Liveeventet sparades");
    }, error => this.api.showError(error));

  }

  save_event()
  {

    this.eventevent.eventID = this.liveevent.id;

    this.api.setLiveEventEvent(this.eventevent).subscribe(() => {
      
      if (this.eventevent.id)
      {
        this.liveevent.events.forEach((item, index) => {

          if (item.id == this.eventevent.id)
          {
            this.liveevent.events[index] = this.eventevent;
''
          }

        })
      }
      else
      {
        this.liveevent.events.push(this.eventevent);
      }

      this.eventevent = new LiveEventEvent();

    })
  }


  public delete() {
    if (confirm("Är du säker på att du vill ta bort det här eventet?")) {

      this.api.delLiveEvent(this.liveevent.id).subscribe((data:any) => {
        this.router.navigateByUrl('/live-events');
      });
    }
  }
}

