import { RecommendCategory } from './recommendCategory';
import { Media } from './media';

export class Recommend {

    public id: number;
    public title: string;
    public latitude: number;
    public longitude: number;
    public status: number;
    public medias: Media[] = [];
    public categories: RecommendCategory[] = [];
    public description: string;
    public place: string;
    public duplicates: Recommend[];
    public add: any[];

}

