import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/Services/data.service';
import { environment } from 'src/environments/environment';
import { DataTablesResponse } from '../../../../../shared/models/datatabelsResponse';
import { PortalText } from '../../../../../shared/models/portalText';

@Component({
  selector: 'app-portal-texts',
  templateUrl: './portal-texts.component.html',
  styleUrls: ['./portal-texts.component.scss']
})
export class PortalTextsComponent implements OnInit {

  baseUrl:string = environment.apiUrl;
  heading = 'Alla portaltexter';
  subheading = 'Lista över alla portaltexter.';
  icon = 'pe-7s-helm icon-gradient bg-malibu-beach';

  public portal_texts: PortalText[] = [];

  dtOptions: DataTables.Settings = {};

  constructor(private data: DataService, private http: HttpClient) {
  }

  ngOnInit(): void {
    const that = this;

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      serverSide: true,
      processing: true,
      language: {
        "emptyTable":     "Tabellen innehåller ingen data",
        "info":           "Visar rad _START_ - _END_ av _TOTAL_ rader",
        "infoEmpty":      "Visar 0 av 0 rader",
        "infoFiltered":   "(av totalt _MAX_ rader)",
        "infoPostFix":    "",
        "thousands":      ",",
        "lengthMenu":     "Visa _MENU_ rader per sida",
        "loadingRecords": "Laddar...",
        "processing":     "Laddar...",
        "search":         "Sök:",
        "zeroRecords":    "Inget innehåll hittat",
        "paginate": {
          "first":      "Första",
          "last":       "Sista",
          "next":       "Nästa",
          "previous":   "Föregående"
        },
        "aria": {
            "sortAscending":  ": activate to sort column ascending",
            "sortDescending": ": activate to sort column descending"
        }
    },
      ajax: (dataTablesParameters: any, callback) => {
        that.http
          .post<DataTablesResponse>(
            this.baseUrl + '/api/portal/texts?app_id=0',
            dataTablesParameters, {}
          ).subscribe(resp => {

            that.portal_texts = resp.data;

            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
      columns: [{ data: 'id' }, { data: 'code' }]
    };
  }

  ngOnDestroy(): void {
  }
}

